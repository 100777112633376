import {createFileRoute} from "@tanstack/react-router";
import {ContractorPortalHome} from "@seeair/shared-components";
import React from 'react'
import {trpc} from "@seeair/shared-components";
import {HomePageSearch, HomePageSearchSchema} from "@seeair/schemas";
export const Route = createFileRoute('/contractor/_contractor/home/$home_id')({
    component: function ContractorViewHomeComponent(){
        const {home_id} = Route.useParams()
        const [home] = trpc.CONTRACTOR.getHome.useSuspenseQuery({home_id})
        const search = Route.useSearch()
        return <ContractorPortalHome home={home} initiallyShowViewer={search?.viewer} selectedProject={search.project} />
    },
    validateSearch: (data)=>{
        const safeParse = HomePageSearchSchema.safeParse(data)
        if(!safeParse.success){
            return {} as HomePageSearch
        }
        return safeParse.data
    }
})