import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {createRouter as rawCreateRouter, ErrorComponent, Link, RouterProvider,} from '@tanstack/react-router'
import {httpBatchLink} from '@trpc/client'
import {initSuperJSON} from "@seeair/shared"
import {trpc} from "@seeair/shared-components"
import {routeTree} from './routeTree.gen.js'
import React, {Suspense, useContext, useState} from 'react'
import {MantineProvider} from '@mantine/core'
import {ModalsProvider} from '@mantine/modals'
import SuperJSON from 'superjson'
import {captureException} from '@sentry/react'
import {
    ErrorBoxPage,
    Layout,
    mantineTheme,
    NotificationsProvider,
    QueryUserDataProvider,
    SuspenseLoader,
    TextLink,
    TextXl,
    UserDataContext,
    UtmDataProvider
} from "@seeair/shared-components";
import {HelmetProvider} from "react-helmet-async";

initSuperJSON()

const LoggedErrors = new WeakSet()
const createRouter = () => {
    return rawCreateRouter({
        routeTree,
        context: {},
        defaultPendingComponent: () => SuspenseLoader,
        defaultErrorComponent: (props) => {
            if (props.error && !LoggedErrors.has(props.error)) {
                LoggedErrors.add(props.error)
                captureException(props.error)
            }
            return <ErrorComponent {...props} />
        },
        // Should be intent, but currently some bugs where preload will navigate to the page in some scenarios??
        // https://github.com/TanStack/router/issues/1382
        defaultPreload: 'intent',
        notFoundMode: 'root',
        defaultNotFoundComponent: () => (
            <Layout>
                <ErrorBoxPage>
                    <TextXl>Not Found</TextXl>
                    <Link
                        preload={false}
                        to="/"
                    >
                        <TextLink>Go home</TextLink>
                    </Link>
                </ErrorBoxPage>
            </Layout>
        ),
    })
}

declare module '@tanstack/react-router' {
    interface Register {
        router: ReturnType<typeof createRouter>
    }
}

function AppRouter() {
    const {user, isLoading} = useContext(UserDataContext)
    const [router] = useState(() => createRouter())

    return <RouterProvider router={router}/>
}
const ReactQueryDevtoolsProduction = React.lazy(() =>
    import('@tanstack/react-query-devtools/production').then((d) => ({
        default: d.ReactQueryDevtools,
    })),
)

export function App() {
    const [showDevtools, setShowDevtools] = React.useState(false)

    React.useEffect(() => {
        (window as any).toggleDevtools = () => setShowDevtools((old) => !old)
    }, [])
    console.log("")
    const [queryClient] = useState(() => {
        return new QueryClient({
            defaultOptions: {
                queries: {
                    staleTime: 15 * 1000,
                    retry: 1,
                    retryDelay: (i) => 3 + i * 3,
                },
            },
        })
    })

    const [trpcClient] = useState(() => {
        return trpc.createClient({
            links: [httpBatchLink({url: '/api/trpc', transformer: SuperJSON})],
        })
    })
    const params = new URLSearchParams(window.location.search)
    const utm_source = params.get("utm_source") ?? "none"
    const utm_content = params.get("utm_content") ?? "none"
    return (
        <React.StrictMode>
            <HelmetProvider>
                <Suspense fallback={SuspenseLoader}>
                    <trpc.Provider client={trpcClient} queryClient={queryClient}>
                        <QueryClientProvider client={queryClient}>
                            <QueryUserDataProvider>
                                <UtmDataProvider data={{
                                    utm_source,
                                    utm_content
                                }}>
                                    <MantineProvider theme={mantineTheme}>
                                        <ModalsProvider>
                                            <NotificationsProvider/>
                                            <AppRouter/>
                                            {showDevtools && (
                                                <React.Suspense fallback={null}>
                                                    <ReactQueryDevtoolsProduction />
                                                </React.Suspense>
                                            )}
                                        </ModalsProvider>
                                    </MantineProvider>
                                </UtmDataProvider>
                            </QueryUserDataProvider>
                        </QueryClientProvider>
                    </trpc.Provider>
                </Suspense>
            </HelmetProvider>
        </React.StrictMode>
    )
}


