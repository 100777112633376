import {GetObjectCommand} from "@aws-sdk/client-s3";
import {NormalizedAddress} from "@seeair/schemas";

export function getAssessmentS3GetObjectCommand(s3Url: string, cxt: {
    env: { S3_BUCKET_ASSESSMENT_FILES: string }
}): GetObjectCommand {
    const Bucket = cxt.env.S3_BUCKET_ASSESSMENT_FILES
    const regex = /^s3:\/\/[^/]*\/(.*)$/
    const match = s3Url.match(regex)
    if (!match || !match[1]) {
        throw `Malformed s3 url: ${s3Url}`
    }
    const Key = match[1]
    return new GetObjectCommand({
        Bucket,
        Key
    })
}
export function getThumbnailKey(normalizedAddress:NormalizedAddress):string {
    return `homes/${encodeURIComponent(normalizedAddress.formattedAddress)}/thubmnail.jpeg`
}

export function getS3BucketHttpUrl(Bucket: string, region: string) {
    return `https://${Bucket}.s3.${region}.amazonaws.com`
}
export function getS3ObjectHttpUrl(Bucket: string, region: string,Key:string) {
    return `${getS3BucketHttpUrl(Bucket, region)}/${encodeURIComponent(Key)}`
}

export function extractBucketAndKeyFromS3Url(s3_url: string) {
    const url = new URL(s3_url);
    return { Bucket: url.hostname, Key: url.pathname.slice(1) };
};
export function extractBucketAndKeyFromHttpUrl(http_url: string) {
    const url = new URL(http_url);
    return { Bucket: url.hostname.split('.')[0], Key: url.pathname.slice(1) };
};