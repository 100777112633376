import {
    DesignedIconButton,
    DesignedButton,
    getUseMutationOpt,
    HDivider,
    HStack,
    showSuccessNotification,
    TextLg,
    trpc,
    VStack
} from "@seeair/shared-components";
import * as Sentry from '@sentry/react'
import {SeverityLevel} from '@sentry/react'
import React, {useState} from 'react'
import {Loader, Menu, NativeSelect, Table} from "@mantine/core";
import {IconBrandAws, IconDots, IconTrash} from "@tabler/icons-react";
import ReactJson from "@microlink/react-json-view";
import {Message} from "@aws-sdk/client-sqs";

export function AdminToolsPane() {
    const [level, setLevel] = useState("info" as SeverityLevel)
    const {
        mutate: sentryBackendTest,
        isPending: isSentryBackendPending
    } = trpc.ADMIN.sentryTest.useMutation(getUseMutationOpt(trpc.useUtils()))
    const {
        mutate: clearSheetsCache,
        isPending: isSheetsCachePending
    } = trpc.ADMIN.clearSheetsAndDocsCache.useMutation(getUseMutationOpt(trpc.useUtils()))
    const {
        mutate: clearProductCache,
        isPending: isProductCachePending
    } = trpc.ADMIN.clearProductCache.useMutation(getUseMutationOpt(trpc.useUtils()))
    const {
        data: dlqMessages,
        isFetching: isDlqFetching,
        refetch: refetchDqlMessages
    } = trpc.ADMIN.readAllDlqMessages.useQuery({},{enabled:false})
    return <VStack center>
        <HStack>
            <DesignedButton onClick={() => {
                Sentry.captureMessage("Test Frontend Message", level)
                showSuccessNotification()
            }}>Sentry FrontEnd Test</DesignedButton>
            <NativeSelect value={level} onChange={(e) => setLevel(e.currentTarget.value as SeverityLevel)}
                          data={["fatal", "error", "warning", "log", "info", "debug"] as Array<SeverityLevel>}/>
        </HStack>
        <DesignedButton disabled={isSentryBackendPending} onClick={() => {
            console.log("mutate called")
            sentryBackendTest()
        }}>Sentry Backend Test</DesignedButton>
        <DesignedButton disabled={isSheetsCachePending} onClick={() => clearSheetsCache()}>Clear Sheets/Docs
            Cache</DesignedButton>
        <DesignedButton disabled={isProductCachePending} onClick={() => clearProductCache()}>Clear Hubspot Product
            Cache</DesignedButton>
        <HDivider/>
        <HStack leftCenter>
            <TextLg>DLQs</TextLg>
            <DesignedIconButton
                disabled={isDlqFetching}
                tooltip={"Refetch DLQ Messages"}
                icon={<IconBrandAws/>}
                onClick={() => refetchDqlMessages()}
            />
        </HStack>
        <Table striped withTableBorder>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>ID</Table.Th>
                    <Table.Th>Body</Table.Th>
                    <Table.Th>Attributes</Table.Th>
                    <Table.Th>Actions</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {
                    isDlqFetching
                        ? <HStack center><Loader/></HStack>
                        : dlqMessages?.map((m) => {
                            return <DlqMessageRow message={m} key={m.MessageId}/>
                        })
                }
            </Table.Tbody>
        </Table>
    </VStack>
}

function DlqMessageRow({message: m}: { message: Message & { QueueUrl: string } }) {

    const {
        mutate: deleteMessage,
        isPending: isDeletePending
    } = trpc.ADMIN.deleteDlqMessage.useMutation(getUseMutationOpt(trpc.useUtils()))
    const sent = parseFloat((m.Attributes ?? {})['SentTimestamp'] ?? "0")
    return <Table.Tr>
        <Table.Td>
            {m.MessageId}
        </Table.Td>
        <Table.Td>
            <ReactJson src={JSON.parse(m.Body ?? "{}")} shouldCollapse={(k)=>k.name=="headers"}/>
        </Table.Td>
        <Table.Td>
            <ReactJson src={m.Attributes ?? {}} collapsed={false}/>
        </Table.Td>
        <Table.Td>
            {sent &&
                <a
                    href={`https://one.newrelic.com/logger?account=4710065&begin=${sent}&end=${sent + 5000}`}
                    target="_blank"
                    rel="noreferrer">NR</a>}
        </Table.Td>
        <Table.Td>
            <Menu>
                <Menu.Target>
                    <div>
                        <DesignedIconButton
                            tooltip="Actions"
                            disabled={isDeletePending}
                            icon={<IconDots/>}
                            onClick={() => {
                            }}
                        />
                    </div>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Item
                        leftSection={<IconTrash/>}
                        disabled={isDeletePending}
                        onClick={() => deleteMessage({
                            QueueUrl: m.QueueUrl,
                            ReceiptHandle: m.ReceiptHandle!
                        })}
                    >
                        Delete
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </Table.Td>
    </Table.Tr>
}