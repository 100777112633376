import {createFileRoute, Outlet} from '@tanstack/react-router'

import {AdminUserRedirectToSignInCheck, Layout, SheetsDataProvider} from "@seeair/shared-components";
import {Helmet} from "react-helmet-async";
import React from "react";
import {ProductsDataProvider} from "@seeair/shared-components";

export const Route = createFileRoute('/admin/_admin')({
    component: function AdminParentRoute() {
        return <Layout>
            <AdminUserRedirectToSignInCheck>
                <SheetsDataProvider>
                    <ProductsDataProvider>
                        <Helmet>
                            <title>SeeAir Admin</title>
                            <meta name="description"
                                  content="SeeAir Admin Dashboard"/>
                        </Helmet>
                        <Outlet/>
                    </ProductsDataProvider>
                </SheetsDataProvider>
            </AdminUserRedirectToSignInCheck>
        </Layout>
    }
})