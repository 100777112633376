import React, {PropsWithChildren} from "react";
import { HubspotProduct, RecommendationDiscount} from "@seeair/schemas";
import {trpc} from "./trpc.js"

type ProductData = {
    products:Array<HubspotProduct>,
    incentives:Array<RecommendationDiscount>
}
const DefaultProductData:ProductData = {
    products: [],
    incentives: []
}
export const ProductDataContext = React.createContext<ProductData>(DefaultProductData)
export function ProductsDataProvider({children}:PropsWithChildren){
    const [products] = trpc.ADMIN.getProductList.useSuspenseQuery()
    const [incentives] = trpc.ADMIN.getIncentiveList.useSuspenseQuery()
    return <ProductDataContext.Provider value={{products, incentives}}>
            {children}
        </ProductDataContext.Provider>
}