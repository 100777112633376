import {createFileRoute} from "@tanstack/react-router";
import {SheetsDataProvider, HomeownerUserRedirectToSignInCheck} from "@seeair/shared-components";
import {trpc} from "@seeair/shared-components";
import React from "react";
import {HomePortal} from "@seeair/homeowner-components";
import {HomePageSearch, HomePageSearchSchema} from "@seeair/schemas";

export const Route = createFileRoute('/webview/home/$home_id/recommendations')({
    component: function RecommendationsWebView() {
        const {home_id} = Route.useParams()
        const [home] = trpc.HOMEOWNER.getHomeAggregate.useSuspenseQuery({home_id})

        return <HomeownerUserRedirectToSignInCheck>
            <SheetsDataProvider>
                <HomePortal home={home} />
            </SheetsDataProvider>
        </HomeownerUserRedirectToSignInCheck>
    },
    validateSearch: (data)=>{
        const safeParse = HomePageSearchSchema.safeParse(data)
        if(!safeParse.success){
            return {} as HomePageSearch
        }
        return safeParse.data
    }

})