import {RHideStack, VStack} from './DesignBase.js';
import React, { useState} from "react";
import {Viewer} from './Viewer.js';
import {HomeAggregate} from "@seeair/schemas";
import {RecommendationsAccordion} from './RecommendationsAccordion.js';
import classNames from "classnames";

export function DigitalTwin({home, urn,isContractor}: { home: HomeAggregate, urn: string,isContractor:boolean }) {
    const [selectedRecs, setSelectedRecs] = useState([] as Array<number>)
    return <RHideStack xl reverse>
        <Viewer
            urn={urn}
            onRecToggleChange={(selectedRecNums) => {
                setSelectedRecs(selectedRecNums)
            }}
            recs={home.recommendations}
            isContractor={isContractor}
            className={classNames("h-screen w-screen",{
                " xl:w-3/5vw":!isContractor
            })}/> {/*this should always be visible*/}
        {
            !isContractor &&
            <VStack classNames="max-w-2/5 min-w-2/5 h-screen bg-slate-200 w-2/5 max-h-screen overflow-auto"
                    style={{width: "40vw"}}>
                <RecommendationsAccordion
                    recs={home.recommendations}
                    projects={home.projects}
                    filteredRecs={selectedRecs}
                    includeProjects={true}
                /> {/*this should be hidden unless on xl screen or if contractor is viewing*/}
            </VStack>
        }


    </RHideStack>
}