import {DesignedIconButton, getUseMutationOpt, HStack} from "@seeair/shared-components";
import {Checkbox, NativeSelect} from "@mantine/core";
import {Assessment, AssessmentStatus, AssessmentStatusSchema} from "@seeair/schemas";
import React,{useState} from "react";
import {trpc} from "@seeair/shared-components";
import {IconCancel, IconDeviceFloppy, IconPencil} from "@tabler/icons-react";

export function AssessmentStatusWidget({assessment}: { assessment: Assessment }) {
    const [editing, setEditing] = useState(false)
    const [status, setStatus] = useState(assessment.assessment_status)
    const [overrideAssessmentStatusChange, setOverrideAssessmentStatusChange] = useState(false)
    const {
        isPending: setAssessmentIsPending,
        mutate: saveStatusChange
    } = trpc.ADMIN.setAssessmentStatus.useMutation(getUseMutationOpt(trpc.useUtils(),()=>setEditing(false)))
    return <HStack leftCenter shrink classNames="border-primary-light-blue px-4">
        <NativeSelect
            label="assessment_status"
            disabled={!editing}
            data={AssessmentStatusSchema.options}
            value={status || "not_started"}
            onChange={(event) => setStatus(event.target.value as AssessmentStatus)}
        />
        <DesignedIconButton
            disabled={(editing && status == assessment.assessment_status) || setAssessmentIsPending}
            onClick={() => {
                if (editing) {
                    saveStatusChange({
                        assessment_id: assessment.assessment_id,
                        home_id: assessment.home_id,
                        status: status!,
                        override: overrideAssessmentStatusChange
                    })
                } else {
                    setEditing(true)
                }
            }}
            icon={editing ? <IconDeviceFloppy /> : <IconPencil/>}
            tooltip={editing ? "Edit" : "Save"}
        />
        {editing && <React.Fragment>
            <Checkbox
                disabled={!editing}
                label="Override"
                checked={overrideAssessmentStatusChange}
                onChange={(event) => setOverrideAssessmentStatusChange(event.currentTarget.checked)}>
            </Checkbox>
            <DesignedIconButton
                disabled={setAssessmentIsPending}
                onClick={()=>{
                    setEditing(false)
                    setStatus(assessment.assessment_status)
                }}
                icon={<IconCancel />}
                tooltip={"Cancel"}
            />
        </React.Fragment>
        }
    </HStack>
}