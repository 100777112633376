import {
    AddressSchema,
    AirSensorDataSchema,
    AssessmentDataCollectionConfigurationSchema,
    AssessmentFilesSchema,
    AssessmentNoteSchema,
    AssessmentStatusSchema,
    EnergyDataRecord,
    EnergyDataRecordSchema,
    FullAssessmentSchema,
    HomeDetails,
    HomeDetailsSchema,
    HubspotEligibilitySurveySchema, ProjectEventsSchema,
    RecDataSchema,
    RecommendationDiscountsSchema,
    RecommendationLineItemsSchema,
    SolarPotential,
    SolarPotentialSchema
} from "./jsonb-schemas.js";
import {createInsertSchema, createSelectSchema} from "drizzle-zod";
import {z} from "zod";
import {
    HubspotLineItemAggregateSchema,
    HubspotMeetingPropertiesSchema,
} from "./hubspot.js";
import {assessments, homes, shares, recommendations, projects} from "./drizzle-energy.js";
import {waitlist,users} from "./drizzle-authentication.js";

const refineAssessment = {
    assessment_data: FullAssessmentSchema.nullish(),
    electric_usage: z.record(z.string(),EnergyDataRecordSchema).nullish(),
    gas_usage: z.record(z.string(),EnergyDataRecordSchema).nullish(),
    oil_usage: z.record(z.string(),EnergyDataRecordSchema).nullish(),
    air_sensor_data: z.record(z.string(),AirSensorDataSchema).nullish(),
    // weather_data: z.object({}),
    solar_data: SolarPotentialSchema.nullish(),
    assessment_files_list: AssessmentFilesSchema.nullish(),
    assessment_notes: z.record(z.string(),AssessmentNoteSchema).nullish(),
    home_details: HomeDetailsSchema.nullish(),
    assessment_meetings: z.record(z.string(),HubspotMeetingPropertiesSchema).nullish(),
    data_collection_requirements: AssessmentDataCollectionConfigurationSchema.nullish()
}
export type Home = z.infer<typeof HomeSchema>

export const AssessmentSchema = createSelectSchema(assessments, refineAssessment)
export type Assessment = z.infer<typeof AssessmentSchema>
export type AnalysisReadyAssessment = Assessment & {home_details:HomeDetails,electric_usage:Record<string,EnergyDataRecord>,gas_usage:Record<string,EnergyDataRecord>,solar_data:SolarPotential}
export const CreateAssessmentSchema = createInsertSchema(assessments, refineAssessment).omit({assessment_id: true})
export const UpdateAssessmentSchema = createInsertSchema(assessments, refineAssessment)
    .omit({
        // we won't allow updating these fields
        home_id: true,
        created_at: true,
    })
    .partial()
    .required({assessment_id: true})

const refineHome = {
    // home_details: HomeDetailsDBSchema,
    address: AddressSchema,
}
export const HomeSchema = createSelectSchema(homes, refineHome)
export const AddHomeSchema = createInsertSchema(homes, refineHome).omit({home_id: true})
export type AddHomeProps = z.infer<typeof AddHomeSchema>
export const GetSharesSchema = createSelectSchema(shares)
export type Share = z.infer<typeof GetSharesSchema>
const refineRecommendation = {
    rec_data:RecDataSchema,
    line_items: RecommendationLineItemsSchema.nullish(),
    discounts: RecommendationDiscountsSchema.nullish()

}
export const GetRecommendationsSchema = createSelectSchema(recommendations,refineRecommendation)
export type Recommendation = z.infer<typeof GetRecommendationsSchema>
export const InsertRecommendationsSchema = createInsertSchema(recommendations,refineRecommendation)
export type NewRecommendation = z.infer<typeof InsertRecommendationsSchema>

const refineProject = {
    hubspot_deal_line_items: z.array(HubspotLineItemAggregateSchema).nullish(),
    hubspot_quote_line_items: z.array(HubspotLineItemAggregateSchema).nullish(),
    homeowner_availability: z.array(z.string()).nullish(),
    scheduled_dates: ProjectEventsSchema.nullish(),
    project_crew: z.array(z.string()).nullish(),
}
export const GetProjectsSchema = createSelectSchema(projects,refineProject)
export type Project = z.infer<typeof GetProjectsSchema>
export const ProjectAggregateSchema = GetProjectsSchema.merge(z.object({
    recommendations:z.array(GetRecommendationsSchema)
}))
export type ProjectAggregate = z.infer<typeof ProjectAggregateSchema>
export const InsertProjectSchema = createInsertSchema(projects,refineProject)
export type InsertProject = z.infer<typeof InsertProjectSchema>
export const CreateProjectSchema = createInsertSchema(projects,refineProject).omit({project_id:true})
export type CreateProject = z.infer<typeof CreateProjectSchema>
export type UpdateProject = Partial<CreateProject> & {project_id:string}
export type AssessmentStatus = z.infer<typeof AssessmentStatusSchema>

const refineUser = {
    // role: UserRolesSchema
    created_at: z.date().nullish(),
    emailVerified: z.date().nullish(),
    company_id: z.string().nullish()
}
export const SeeAirUserSchema = createSelectSchema(users,refineUser)
export type SeeAirUser = z.infer<typeof SeeAirUserSchema>
const refineWaitlist = {
    eligibility_data:HubspotEligibilitySurveySchema
}
export const GetWaitlistSchema = createSelectSchema(waitlist,refineWaitlist)
export type Waitlist = z.infer<typeof GetWaitlistSchema>