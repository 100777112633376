import {createTheme, Loader, Modal} from "@mantine/core";

export const PRIMARY_LIGHT_BLUE = "#058FCC"
export const PRIMARY_BLUE = "#03045E"
export const SECONDARY_BLUE = "#037CB0"
export const COLOR_WHITE = "#FFFFFF"
export const COLOR_LIGHT_GREY = "#E1E3E5"
export const COLOR_GREY = "#3B444B"
export const COLOR_DARK_GREY = "#0E1111"
export const COLOR_LIGHT_BLUE = "#CAF0F8"
export const COLOR_ORANGE = "#F97258"
export const COLOR_YELLOW = "#FFFD82"
export const COLOR_GREEN = "#14C693"
export const COLOR_CHARCOAL = '#3B444B'
export const GRADIENT_NAVY = `linear-gradient(96deg, ${PRIMARY_BLUE} 64.98%, ${PRIMARY_LIGHT_BLUE} 147.53%)`


export const mantineTheme = createTheme({
    components: {
        Modal: Modal.extend({
            classNames: {
                title: "text-lg font-semibold",
            },
        }),
        Loader: Loader.extend({
            // defaultProps: {
            // 	color: token('colors.blue.200'),
            // 	// color: '#0C8CE9',
            // },
        }),
    }
})