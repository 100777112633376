import {
    EnergyChart,
    HStack,
    RecommendationsAccordion,
    ShareButton,
    SharePanel,
    Text3Xl,
    TextBase,
    TextLg, TextRXl,
    VStack
} from "@seeair/shared-components";
import React, { useState} from "react";
import {
    Assessment,
    HomeAggregate
} from "@seeair/schemas";
import {Modal, SegmentedControl, Tabs} from "@mantine/core";
import {buildShortAddress, formatMoney, formatNumber, getLatestFile, getLatestFinishedAssessment} from "@seeair/shared";
import { DigitalTwinSection} from "@seeair/shared-components";
import {Icon3dCubeSphere, IconBolt, IconHammer} from "@tabler/icons-react";
import classNames from "classnames";

type Show = 'usage' | 'cost'

export function HomePortal({home,initiallyShowViewer}: { home: HomeAggregate,initiallyShowViewer?:boolean }) {
    const [currentModal, setCurrentModal] = useState<'share'>()
    const [tabSelected,setTabSelected] = useState(initiallyShowViewer?"viewer":"plan" as string | null)

    const latestAssessment = getLatestFinishedAssessment(home)
    return <VStack classNames="flex-grow bg-white">
        <HStack center>
            <TextRXl>{buildShortAddress(home.address)}</TextRXl>
            {(home.permissions?.has('owner') ?? false) && (
                <ShareButton onClick={() => setCurrentModal('share')}/>
            )}
        </HStack>
        <Tabs value={tabSelected} onChange={setTabSelected}>
            <Tabs.List className="justify-center" classNames={{}}>
                <Tabs.Tab
                    className={classNames("border border-gray-500",{
                    "bg-gray-200":tabSelected=="energy"})}
                    value="energy"
                    leftSection={<IconBolt />}>Energy Usage</Tabs.Tab>
                <Tabs.Tab
                    className={classNames("border border-gray-500",{
                        "bg-gray-200":tabSelected=="viewer"})}
                    value="viewer"
                    leftSection={<Icon3dCubeSphere />}>Digital Twin</Tabs.Tab>
                <Tabs.Tab
                    className={classNames("border border-gray-500",{
                        "bg-gray-200":tabSelected=="plan"})}
                    value="plan"
                    leftSection={<IconHammer />}>Improvement Plan</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="energy">
                <HomePortalEnergy latestAssessment={latestAssessment} />
            </Tabs.Panel>
            <Tabs.Panel value="viewer">
                <HomePortalViewer latestAssessment={latestAssessment} initiallyShowViewer={!!initiallyShowViewer} home={home}/>
            </Tabs.Panel>
            <Tabs.Panel value="plan">
                <HomePortalPlan home={home} />
            </Tabs.Panel>
        </Tabs>
        <Modal title="Shares" opened={currentModal == 'share'} onClose={() => setCurrentModal(undefined)}>
            <SharePanel home={home} />
        </Modal>
    </VStack>
}
function HomePortalPlan({home}:{home:HomeAggregate}) {
    return <VStack center>
        <VStack classNames="max-w-screen-xl">
            {
                home.recommendations.length == 0
                    ?
                    <TextBase>Your home is healthy and energy efficient, thanks for doing your part to save
                        the
                        world!</TextBase>
                    : <RecommendationsAccordion
                        recs={home.recommendations}
                        projects={home.projects}
                        filteredRecs={[]}
                        includeProjects={true}
                        navigateToProject={()=>{}}
                    />
            }
        </VStack>
    </VStack>
}
function HomePortalViewer({home,latestAssessment,initiallyShowViewer}:{initiallyShowViewer:boolean,home:HomeAggregate,latestAssessment:Assessment|'not_found'}) {
    let latestRenderingFile
    if (latestAssessment != 'not_found') {
        latestRenderingFile = getLatestFile(latestAssessment, 'capture_rendering_enhanced')
    }
    return <VStack center>
        <Text3Xl light center>Digital Twin</Text3Xl>
        <DigitalTwinSection file={latestRenderingFile} home={home} initiallyShowViewer={initiallyShowViewer} isContractor={false}/>
    </VStack>
}

function HomePortalEnergy({latestAssessment}:{latestAssessment:Assessment|'not_found'}) {
    let electrification
    if (latestAssessment != 'not_found') {
        electrification = latestAssessment.assessment_data?.electrification
    }
    const costGenerated = electrification?.energy_chart?.some(o => o.Heating_Cost != null)
    const [show, setShow] = useState(costGenerated ? 'cost' : 'usage' as Show)
    const hasGas = (electrification?.energy_use.Gas ?? 0) > 0
    const hasOil = (electrification?.energy_use.Oil ?? 0) > 0
    let electricAnnual, gasAnnual, oilAnnual
    if (latestAssessment != 'not_found' && electrification) {
        if (show == "usage") {
            electricAnnual = `${formatNumber(electrification.energy_use.Electric, 0)} kWh`
            gasAnnual = `${formatNumber(electrification.energy_use.Gas ?? 0, 0)} Therms`
            oilAnnual = `${formatNumber(electrification.energy_use.Oil ?? 0, 0)} Therms`
        } else {
            electricAnnual = `${formatMoney(electrification.energy_cost.Electric)}`
            gasAnnual = `${formatMoney(electrification.energy_cost.Gas ?? 0)}`
            oilAnnual = `${formatMoney(electrification.energy_cost.Oil ?? 0)}`
        }
    }
    return <VStack center>
        <HStack center>
            <Text3Xl light center>Heating & Cooling Energy Costs</Text3Xl>
            {costGenerated && <SegmentedControl className="ml-8" value={show} onChange={(v) => setShow(v as Show)}
                                                data={[{label: "Cost", value: 'cost'}, {
                                                    label: "Usage",
                                                    value: 'usage'
                                                }]}/>}
        </HStack>
        {
            electrification
                ? <React.Fragment>
                    <HStack baseline center>
                        <TextLg classNames="pr-4">Annual Electric Usage:&nbsp;</TextLg>
                        <TextLg light>{electricAnnual}</TextLg>
                        {hasGas && <React.Fragment>
                            <TextLg classNames="pr-4 pl-8">Annual Gas Usage:&nbsp;</TextLg>
                            <TextLg light>{gasAnnual}</TextLg>
                        </React.Fragment>}
                        {hasOil && <React.Fragment>
                            <TextLg classNames="pr-4 pl-8">Annual Oil Usage:&nbsp;</TextLg>
                            <TextLg light>{oilAnnual}</TextLg>
                        </React.Fragment>}
                    </HStack>
                    <EnergyChart
                        mobile
                        width={850}
                        height={350}
                        energy_chart={electrification.energy_chart}
                        show={show}
                    />
                </React.Fragment>
                : <TextBase>Energy Data Missing</TextBase>
        }
    </VStack>
}

