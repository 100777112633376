import {Project, ProjectAggregate, Recommendation} from "@seeair/schemas";
import React, {useContext, useEffect, useState} from "react";
import {Accordion, SegmentedControl, UnstyledButton} from "@mantine/core";
import {trpc} from "./trpc.js"
import {notifications} from "@mantine/notifications";
import classNames from "classnames";
import {IconChevronDown} from "@tabler/icons-react";
import {DesignedAccordionControl, HStack, TextLink, TextSmItalic, VStack} from "./DesignBase.js";
import {SheetsDataContext} from './SheetsDataContext.js';
import {Badge} from './DesignComponents.js';
import {
    IconHeartChecked,
    IconHeartUnchecked,
    IconLeafChecked,
    IconLeafUnchecked,
    IconPriceTagChecked,
    IconPriceTagUnchecked
} from './Icons.js';
import {getProjectStage, getProjectStageString, recStatusToString, recToNum} from "@seeair/shared";
import {ProjectPanel} from "./ProjectsAccordion.js";
import {ImpactSummary} from './RecommendationsAccordionCommon.js';

function filter(r: Recommendation, filteredRecs: Array<number>, projects: Array<ProjectAggregate>, includeProjects: boolean): boolean {
    if (filteredRecs.length == 0) {
        return true
    }
    if (filteredRecs.includes(parseInt(r.original_rec_id))) {
        return true
    }
    //if it has a project
    if (includeProjects && r.project_id) {
        const project = projects.find(p => p.project_id == r.project_id)
        if (project) {
            //if some rec on the same project is in filtered recs, then we'll show that rec/project too
            return project.recommendations.some(pr => filteredRecs.includes(parseInt(pr.original_rec_id)))
        }
    }
    return false
}

export function RecommendationsAccordion({recs, projects, filteredRecs, includeProjects, navigateToProject}: {
    recs: Array<Recommendation>,
    projects: Array<ProjectAggregate>,
    filteredRecs: Array<number>,
    includeProjects: boolean,
    navigateToProject?: (project_id: string) => void
}) {
    const sortedRecs = [...recs]
        .filter(r => filter(r, filteredRecs, projects, includeProjects))
        .sort((a, b) => recToNum(a, projects) - recToNum(b, projects))
    const [selected, setSelected] = useState(sortedRecs[0]?.original_rec_id ?? null)
    useEffect(() => {
        setSelected(sortedRecs[0]?.original_rec_id ?? null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredRecs]);
    const renderedProjectIds = new Set<string>()
    return <VStack classNames="my-4">
        <Accordion
            transitionDuration={300}
            maw={"100%"}
            variant="separated"
            radius="xl"
            value={selected}
            onChange={setSelected}>
            {sortedRecs
                .map(r => {
                    let project: ProjectAggregate | undefined = undefined
                    if (r.project_id) {
                        project = projects.find(p => p.project_id == r.project_id)
                        if (project && includeProjects) {
                            const stage = getProjectStage(project)
                            if (renderedProjectIds.has(project.project_id)) {
                                return null //don't render the same project twice
                            } else {
                                renderedProjectIds.add(project.project_id)
                            }

                            return <Accordion.Item key={project.project_id}
                                                   value={r.original_rec_id}
                                                   className="border-primary-light-blue mx-4">
                                <DesignedAccordionControl
                                    rec_numbers={project.recommendations.map(r => r.original_rec_id)}
                                    badgeRight={<Badge blue={stage == 'quoted'}
                                                       green={stage == 'done'}
                                                       gray={stage == 'drafting'}
                                    >
                                        <b>Status</b><br/> {getProjectStageString(stage)}
                                    </Badge>}
                                    title={project.project_title}/>
                                <Accordion.Panel>
                                    <ProjectPanel project={project}/>
                                </Accordion.Panel>

                            </Accordion.Item>
                        }
                    }
                    return <Accordion.Item value={r.original_rec_id}
                                           key={r.recommendation_id}
                                           className="border-primary-light-blue mx-4">
                        <DesignedAccordionControl
                            rec_numbers={[r.original_rec_id]}
                            title={`${r.title}${r.type=="PRO"?"":" (DIY)"}`}
                            badgeRight={<Badge
                                green={r.status == 'in_progress'}
                                gray={r.status == 'not_started'}
                            ><b>Status</b><br />{recStatusToString(r)}
                            </Badge>}/>
                        <Accordion.Panel>
                            <RecommendationPanel rec={r}
                                                 key={r.recommendation_id}
                                                 project={project}
                                                 initiallyExpanded={false /*sortedRecs.length == 1*/}
                                                 navigateToProject={navigateToProject}
                            />
                        </Accordion.Panel>
                    </Accordion.Item>
                })}
        </Accordion>
        {sortedRecs.length != recs.length && <TextSmItalic center wFull
                                                           classNames="mt-8">{`${recs.length - sortedRecs.length} filtered results`}</TextSmItalic>}
    </VStack>
}

export function RecommendationPanel({rec, project, initiallyExpanded, navigateToProject}: {
    rec: Recommendation,
    project: Project | undefined | null,
    initiallyExpanded: boolean,
    navigateToProject?: (project_id: string) => void
}) {
    const {recommendationsSheet} = useContext(SheetsDataContext)
    const matchingRec = recommendationsSheet.find((_rec) => _rec.id === rec.original_rec_id)
    const [expanded, setExpanded] = useState(initiallyExpanded)
    const utils = trpc.useUtils()
    const {
        isPending: isSetRecStatusPending,
        mutate: setRecStatus
    } = trpc.HOMEOWNER.changeRecommendationStatus.useMutation({
        onSuccess: async (_, variables) => {
            await utils.HOMEOWNER.invalidate()
            if (variables.status == "in_progress" && rec.type == "PRO") {
                notifications.show({message: "Your SeeAir Energy Advisor will be in touch shortly to start this project"})
            } else {
                notifications.show({message: "Update Success"})
            }
        },
        onError: (error) => {
            notifications.show({message: `Failure: ${error.message}`})
        }
    })
    return <div className={classNames("border-t-primary-light-blue",)}>
        {!project && rec.type == 'DIY' && <HStack center>
            <SegmentedControl
                className="my-4"
                disabled={isSetRecStatusPending}
                data={[
                    {label: "Not Started", value: "not_started"},
                    {label: "In Progress", value: "in_progress"},
                    {label: "Done", value: 'done'}]}
                value={rec.status}
                onChange={(v) => {
                    setRecStatus({
                        home_id: rec.home_id,
                        recommendation_id: rec.recommendation_id,
                        status: v as 'not_started' | 'done' | 'in_progress'
                    })
                }}
            />
        </HStack>}

        <ImpactSummary checkedIcon={<IconHeartChecked/>} uncheckedIcon={<IconHeartUnchecked/>}
                       score={matchingRec?.healthCategory || 0}
                       summary={matchingRec?.healthSummary || ""} title={"Health Impact"}
                       description={matchingRec?.healthDescription || ""} expanded={expanded} category="health"
                       rec={null}/>
        <ImpactSummary checkedIcon={<IconLeafChecked/>} uncheckedIcon={<IconLeafUnchecked/>}
                       score={matchingRec?.climateCategory || 0}
                       summary={matchingRec?.climateSummary || ""} title={"Climate Impact"}
                       description={matchingRec?.climateDescription || ""} expanded={expanded}
                       category="climate" rec={null}/>
        <ImpactSummary checkedIcon={<IconPriceTagChecked/>} uncheckedIcon={<IconPriceTagUnchecked/>}
                       score={matchingRec?.priceCategory || 0}
                       summary={matchingRec?.priceSummary || ""} title={"Price Impact"}
                       description={matchingRec?.priceDescription || ""} expanded={expanded}
                       rec={rec} category="price"/>
        {project && navigateToProject &&
            <VStack center classNames="mb-8">
                <HStack center><UnstyledButton onClick={() => navigateToProject(project.project_id)}><TextLink>View
                    Project</TextLink></UnstyledButton></HStack>
            </VStack>
        }
        <a onClick={() => setExpanded(!expanded)} className="flex flex-row justify-center items-center">
            <TextLink classNames="transition">{
                expanded
                    ? "Hide"
                    : "More Info"
            }
            </TextLink><IconChevronDown
            className={classNames("text-blue-400 transition transform", {"actually-rotate-180": expanded})}/></a>
    </div>
}
