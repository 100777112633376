import {RecData, RecommendationAggregate} from "@seeair/schemas";
import React, {useState} from "react";
import {DesignedIconButton, getUseMutationOpt, HStack, TextSm, trpc, VStack} from "@seeair/shared-components";
import {NumberInput, Table} from "@mantine/core";
import {IconCancel, IconDeviceFloppy, IconPencil} from "@tabler/icons-react";
import {formatMoney} from "@seeair/shared";


export function RecommendationFinancialsTable({rec, includeEstimates}: {
    rec: RecommendationAggregate,
    includeEstimates: boolean
}) {
    const [editing, setEditing] = useState(false)
    const [data, setData] = useState(rec.rec_data)
    const {
        mutate: saveRecData,
        isPending: saveRecDataIsPending
    } = trpc.ADMIN.setRecommendationData.useMutation(getUseMutationOpt(trpc.useUtils(), () => setEditing(false)))
    return <VStack>
        <Table
            striped
            withTableBorder
        >
            <Table.Thead>
                <Table.Tr>
                    <Table.Td colSpan={10}><VStack center><TextSm>Estimates</TextSm></VStack></Table.Td>
                </Table.Tr>
                <Table.Tr>
                    <Table.Th/>
                    <Table.Th>High</Table.Th>
                    <Table.Th>Low</Table.Th>
                    <Table.Th><HStack rightCenter>
                        {editing && <DesignedIconButton
                            disabled={saveRecDataIsPending}
                            icon={editing ? <IconDeviceFloppy/> : <IconPencil/>}
                            onClick={() => {
                                saveRecData({
                                    home_id: rec.home_id,
                                    recommendation_id: rec.recommendation_id,
                                    rec_data: data
                                })
                            }}
                            tooltip={"Save Estimates"}/>}
                        <DesignedIconButton
                            disabled={saveRecDataIsPending}
                            icon={editing ? <IconCancel/> : <IconPencil/>}
                            onClick={() => {
                                if (editing) {
                                    setData(rec.rec_data)
                                }
                                setEditing(!editing)
                            }}
                            tooltip={editing ? "Cancel" : "Edit Estimates"}/>
                    </HStack>
                    </Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {includeEstimates && <Table.Tr>
                    <Table.Td>Upfront Cost</Table.Td>
                    <FinancialInputField setData={setData} data={data} editing={editing} propKey={'Upfront_Cost_High'}/>
                    <FinancialInputField setData={setData} data={data} editing={editing} propKey={'Upfront_Cost_Low'}/>
                </Table.Tr>}
                {includeEstimates &&
                    <Table.Tr>
                        <Table.Td>Net Cost</Table.Td>
                        <FinancialInputField setData={setData} data={data} editing={editing} propKey={'Net_Cost_High'}/>
                        <FinancialInputField setData={setData} data={data} editing={editing} propKey={'Net_Cost_Low'}/>
                    </Table.Tr>
                }
                <Table.Tr>
                    <Table.Td>Annual Savings Cost</Table.Td>
                    <FinancialInputField setData={setData} data={data} editing={editing}
                                         propKey={'Annual_Savings_High'}/>
                    <FinancialInputField setData={setData} data={data} editing={editing}
                                         propKey={'Annual_Savings_Low'}/>
                </Table.Tr>
            </Table.Tbody>
        </Table>
    </VStack>

}

function FinancialInputField({editing, data, setData, propKey}: {
    editing: boolean,
    data: RecData,
    setData: (data: RecData) => void,
    propKey: keyof Omit<Omit<RecData, 'Prerequisites'>, 'incentives'>
}) {
    // console.log(`input row ${propKey} value: ${data[propKey]}`)
    return <Table.Td>
        {
            editing
                ? <NumberInput
                    prefix="$"
                    max={Number.MAX_SAFE_INTEGER}
                    min={Number.MIN_SAFE_INTEGER}
                    decimalScale={2}
                    fixedDecimalScale
                    value={data[propKey]}
                    onChange={(value) => {
                        const newData = {...data, [propKey]: parseFloat(value as string)}
                        // console.log(`new data ${propKey} ${value}: ${JSON.stringify(newData)}`)
                        setData(newData)
                    }}/>
                : formatMoney(data[propKey] ?? 0)
        }
    </Table.Td>
}