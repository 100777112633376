import {z} from "zod";
import {extendZodWithOpenApi} from "@asteasolutions/zod-to-openapi";

extendZodWithOpenApi(z);
export const ASSESSMENT_LABEL_BASELINE = "Baseline"
export const AssessmentStatusSchema = z.enum([
    'not_started',
    'homeowner_providing_inputs',
    'collecting_data',
    'bim_team_analysis',
    'internal_review',
    'pending_homeowner_review',
    'done'
])
export const HubspotEligibilitySurveySchema = z.object({
    email: z.string(),
    firstname: z.string().nullish(),
    lastname: z.string().nullish(),
    utility_providers: z.string().nullish(),
    address: z.string().nullish(),
    address_line_2: z.string().nullish(),
    city: z.string().nullish(),
    zip: z.string().nullish(),
    country: z.string().nullish(),
    state: z.string().nullish(),
    ownership: z.string().nullish(),
    priorities: z.string().nullish(),
    prior_assessment: z.string().nullish(),
    prior_assessment_date: z.string().nullish(),
    wall_insulation: z.string().nullish(),
    discounted_rate: z.string().nullish(),
})
export type HubspotEligibilitySurveyData = z.infer<typeof HubspotEligibilitySurveySchema>
export const HubspotRentcastDetailsSchema = z.object({
    beds: z.string().nullish(),
    baths: z.string().nullish(),
    yearBuilt: z.string().nullish(),
    sqft: z.string().nullish(),
    fireplace: z.string().nullish(),
    floors: z.string().nullish(),
})
export type HubspotRentcastDetails = z.infer<typeof HubspotRentcastDetailsSchema>
// const currentYear = new Date().getFullYear() // this breaks and returns 1970 in cloudflare
export const BaseHomeDetailsSchema = z.object({
    beds: z.number().min(0).max(100).optional().describe("Beds"),
    sqft: z.number().min(0).optional(),
    baths: z.number().min(0).max(100).optional(),
    floors: z.number().min(0).max(10).optional(),
    yearBuilt: z.number().min(0).optional(),
    fireplace: z.boolean().optional(),
    discounted_rate: z.boolean().optional(),
    prior_assessment: z.boolean().optional(),
})
export type BaseHomeDetails = z.infer<typeof BaseHomeDetailsSchema>

export const HomeWalkthroughFilesSchema = z.object({
    window_image: z.string().url().optional()
})
export type HomeWalkthroughFiles = z.infer<typeof HomeWalkthroughFilesSchema>
const StoveEnumSchema = z.enum(["Gas", "Electric", "Induction"])
export type StoveEnum = z.infer<typeof StoveEnumSchema>
const LightbulbEnumSchema = z.enum(["Incandescent", "CFL", "Halogen", "LED"])
export type LightbulbEnum = z.infer<typeof LightbulbEnumSchema>
export const HomeWalkthroughDetailsSchema = z.object({
    lightbulbs: z.array(LightbulbEnumSchema).optional(),
    stove: StoveEnumSchema.optional(),
    stove_secondary: StoveEnumSchema.optional(),
    stove_tertiary: StoveEnumSchema.optional(),
    thermostat: z.enum(["Smart", "Traditional", "Programmable"]).optional(),
    solar: z.boolean().optional(),
    solar_orientation: z.array(z.enum(["North", "Northeast", "East", "Southeast", "South", "Southwest", "West", "Northwest"])).optional(),
    solar_age: z.enum(["<5 Years", "5-10 Years", "10-15 Years", "15-20 Years", ">20 Years"]).optional(),
    solar_count: z.number().optional(),
    solar_capacity: z.number().optional(),
    battery: z.boolean().optional(),
    battery_capacity: z.number().optional(),
})
export type HomeWalkthroughDetails = z.infer<typeof HomeWalkthroughDetailsSchema>

export const HomeWalkthroughSurveyResultsSchema =
    HomeWalkthroughDetailsSchema.merge(HomeWalkthroughFilesSchema)
export type HomeWalkthroughSurveyResults = z.infer<typeof HomeWalkthroughSurveyResultsSchema>


export const HeatingAndCoolingWalkthroughFilesSchema = z.object({
    insulation_image: z.string().url().optional(),
    crawlspace_image: z.string().url().optional(),
    primary_cooling_image: z.string().url().optional(),
    secondary_cooling_image: z.string().url().optional(),
    primary_heating_image: z.string().url().optional(),
    secondary_heating_image: z.string().url().optional(),
    water_heater_image: z.string().url().optional(),
})
export const UtilityAgeSchema = z.enum(["<5 Years", "5-10 Years", "10-15 Years", "15-20 Years", ">20 Years", "Not Applicable"])
export type UtilityAgeEnum = z.infer<typeof UtilityAgeSchema>
export type HeatingAndCoolingWalkthroughFiles = z.infer<typeof HeatingAndCoolingWalkthroughFilesSchema>
export const HeatingApplianceEnumSchema = z.enum(["Central gas furnace", "Room (through-the-wall) gas furnace",
    "Gas boiler", "Propane (LPG) central furnace", "Propane (LPG) wall furnace / Propane (LPG) boiler",
    "Oil furnace", "Oil Boiler", "Electric furnace", "Electric heat pump", "Electric baseboard heater",
    "Ground coupled heat pump", "Minisplit (ductless) heat pump", "Electric boiler", "Wood Stove", "Pellet Stove", "None"])
export type HeatingApplianceEnum = z.infer<typeof HeatingApplianceEnumSchema>
export const HeatingVentingEnumSchema = z.enum(["atmospheric", "vented"])
export type HeatingVentingEnum = z.infer<typeof HeatingVentingEnumSchema>
export const CoolingApplianceEnumSchema = z.enum(["Central air conditioner", "Room air conditioner", "Electric heat pump",
    "Minisplit (ductless) heat pump", "Ground coupled heat pump", "Direct evaporative cooling", "None"])
export type CoolingApplianceEnum = z.infer<typeof CoolingApplianceEnumSchema>
export const HeatingAndCoolingWalkthroughDetailsSchema = z.object({
    attic: z.boolean().optional(),
    attic_accessible: z.boolean().optional(),
    crawlspace: z.boolean().optional(),
    primary_cooling: CoolingApplianceEnumSchema.optional(),
    primary_cooling_age: UtilityAgeSchema.optional(),
    primary_cooling_merv: z.enum(["MERV 8", "MERV 9", "MERV 10", "MERV 11", "MERV 12", "MERV 13", "MERV 14", "HEPA", "None", "Not Applicable"]).optional(),
    secondary_cooling: CoolingApplianceEnumSchema.optional(),
    secondary_cooling_age: UtilityAgeSchema.optional(),
    secondary_cooling_merv: z.enum(["MERV 8", "MERV 9", "MERV 10", "MERV 11", "MERV 12", "MERV 13", "MERV 14", "HEPA", "None", "Not Applicable"]).optional(),
    electric_panel: z.enum(["100amp", "200amp", "Other"]).optional(),
    primary_heating: HeatingApplianceEnumSchema.optional(),
    primary_heating_age: UtilityAgeSchema.optional(),
    primary_heating_venting: HeatingVentingEnumSchema.optional(),
    secondary_heating: HeatingApplianceEnumSchema.optional(),
    secondary_heating_age: UtilityAgeSchema.optional(),
    secondary_heating_venting: HeatingVentingEnumSchema.optional(),
    water_heater_age: UtilityAgeSchema.optional(),
    water_heating_venting: HeatingVentingEnumSchema.optional(),
})
export type HeatingAndCoolingWalkthroughDetails = z.infer<typeof HeatingAndCoolingWalkthroughDetailsSchema>

export const HeatingAndCoolingWalkthroughSurveyResultsSchema =
    HeatingAndCoolingWalkthroughDetailsSchema.merge(HeatingAndCoolingWalkthroughFilesSchema)

export const DerivedHomeDetailsSchema = z.object({
    //window_image
    window_type: z.enum(["Single Pane", "Double Pane", "Triple Pane"]).optional(),
    //insulation_image

    insulation_type: z.enum([
        "Cellulose, loose fill",
        "Cellulose, high density",
        "Fiberglass, batts",
        "Fiberglass, loose fill",
        "Fiberglass, loose fill, fluffed below manufacturer's standards",
        "Rockwool",
        "Vermiculite",
        "Poly-isocyanurate, rigid board",
        "Polystyrene, expanded rigid board",
        "Polystyrene, extruded rigid board",
        "Low Density Urethane, sprayed foam",
        "Urethane, sprayed foam",
        "Urea Formaldehyde Foam"
    ]).optional(),
    insulation_depth: z.number().optional(),
    //primary_cooling_image
    primary_cooling_btus: z.number().optional(),
    primary_cooling_seer: z.number().optional(),
    //secondary_cooling_image
    secondary_cooling_btus: z.number().optional(),
    secondary_cooling_seer: z.number().optional(),
    //primary_heating_image
    primary_heating_btus: z.number().optional(),
    primary_heating_afue: z.number().optional(),
    //secondary_heating_image
    secondary_heating_btus: z.number().optional(),
    secondary_heating_afue: z.number().optional(),
    //water_heater_image
    water_heater_btus: z.number().optional(),
    water_heater_afue: z.number().optional(),
    water_heater_type: z.enum([
        "Tank-less Electric Water Heater",
        "Tank-less Natural Gas Water Heater",
        "Tank Natural Gas Water Heater",
        "Tank Heat Pump Water Heater",
        "Tank Electric Water Heater",
        "Tank Solar Water Heater",
    ]).optional()
})

export const HomeDetailsSchema = BaseHomeDetailsSchema
    .merge(HomeWalkthroughDetailsSchema)
    .merge(HeatingAndCoolingWalkthroughDetailsSchema)
    .merge(DerivedHomeDetailsSchema)
export type HomeDetails = z.infer<typeof HomeDetailsSchema>

export const TypeFormHomeDetailsSchema =
    HomeWalkthroughDetailsSchema
        .merge(HomeWalkthroughFilesSchema)
        .merge(HeatingAndCoolingWalkthroughDetailsSchema)
        .merge(HeatingAndCoolingWalkthroughFilesSchema)
export const PartialTypeFormHomeDetailsSchema = TypeFormHomeDetailsSchema
    .partial()
export type PartialTypeFormHomeDetails = z.infer<typeof PartialTypeFormHomeDetailsSchema>
export const PartialHomeDetailsDBSchema = HomeDetailsSchema.partial()
export type PartialHomeDetailsDB = z.infer<typeof PartialHomeDetailsDBSchema>

// Mapping a zod Object into a zod union schema with objects of the original schema
export function mapZodObjectToZodUnion(homeDetails: z.ZodObject<any>) {
    const zodForm: z.ZodTypeAny[] = []
    for (const field in homeDetails.shape) {
        zodForm.push(
            z.object({
                [field]: homeDetails.shape[field],
            }).describe(field)
        )
    }
    return z.object({
        Details: z.union(zodForm as [z.ZodTypeAny, z.ZodTypeAny, ...z.ZodTypeAny[]]).optional()
    })
}
export const RecommendationDiscountSchema = z.object({
    incentive_id:z.string(),
    title: z.string(),
    timeOfPurchase: z.boolean(),
    discountOnRemainder: z.boolean(),
    url: z.string().optional(),
    // amount: z.number().optional(), //moved to using cap in place of amount to simplify
    percentage: z.number(),
    cap:z.number(),
    recNums:z.array(z.string()),
    order:z.number(),
})
export type RecommendationDiscount = z.infer<typeof RecommendationDiscountSchema>

export const RecommendationDiscountsSchema = z.record(z.string(),RecommendationDiscountSchema)
export type RecommendationDiscounts = Record<string,RecommendationDiscount>
export const RecommendationLineItemSchema = z.object({
    line_item_id:z.string(),
    name:z.string(),
    quantity:z.number(),
    description:z.string().nullish(),
    price_per_unit:z.number(),
    product_id:z.string(),
    discounts:z.record(z.string(),RecommendationDiscountSchema)
})
export type RecommendationLineItem = z.infer<typeof RecommendationLineItemSchema>
export const RecommendationLineItemsSchema = z.record(z.string(),RecommendationLineItemSchema)
export type RecommendationLineItems = Record<string,RecommendationLineItem>
export const RecommendationIncentiveSchema = z.object({
    title: z.string(),
    timeOfPurchase: z.boolean(),
    discountOnRemainder: z.boolean().optional(),
    url: z.string().optional(),
    amountLow: z.number().optional(),
    amountHigh: z.number().optional(),
    percentage: z.number().optional(),
    cap:z.number().optional()
})
export type RecommendationIncentive = z.infer<typeof RecommendationIncentiveSchema>
export const RecommendationFinancialsSchema = z.object({
    Upfront_Cost_Low: z.number(),
    Upfront_Cost_High: z.number(),
    // incentives: z.array(RecommendationIncentiveSchema).optional(),
    Net_Cost_Low: z.number().optional(),
    Net_Cost_High: z.number().optional(),
    Annual_Savings_Low: z.number(),
    Annual_Savings_High: z.number(),
})
export const AnalysisDataValueSchema = z.object({
    key: z.string(),
    co2: z.number().nullable(),
    pm25: z.number().nullable(),
    voc: z.number().nullable(),
    temp: z.number().nullable(),
    rh: z.number().nullable(),
    co2_threshold: z.number().nullable(),
    pm25_threshold: z.number().nullable(),
    voc_threshold: z.number().nullable(),
    temp_threshold: z.number().nullable(),
    rh_threshold: z.number().nullable(),
    aer: z.number().nullable(),
    rvalue: z.number().nullable(),
})
export type AnalysisDataValue = z.infer<typeof AnalysisDataValueSchema>
export const AnalysisDataSchema = z.record(
    z.string(),
    AnalysisDataValueSchema
)
export type AnalysisData = z.infer<typeof AnalysisDataSchema>
export const EnergyChartMonthSchema = z.object({
    Date: z.coerce.date(),
    Baseload_Usage: z.number(),
    Heating_Usage: z.number(),
    Cooling_Usage: z.number(),
    Baseload_Cost: z.number().nullish(),
    Heating_Cost: z.number().nullish(),
    Cooling_Cost: z.number().nullish(),
})
export type EnergyChartMonth = z.infer<typeof EnergyChartMonthSchema>
export const ElectrificationDataSchema = z.object({
    energy_use: z.object({Electric: z.number(), Gas: z.number().nullish(), Oil: z.number().nullish()}),
    energy_cost: z.object({Electric: z.number(), Gas: z.number().nullish(), Oil: z.number().nullish()}),
    energy_chart: z.array(EnergyChartMonthSchema),
    hvac_sizing: z.object({
        heating_load_current: z.number().nullish(),
        cooling_load_current: z.number().nullish(),
        // heating
        heating_load: z.number().nullish(),
        heating_infiltration: z.number().nullish(),
        heating_insulation: z.number().nullish(),
        heating_radiation: z.number().nullish(),
        heating_afue: z.number().nullish(),
        // cooling
        cooling_load: z.number().nullish(),
        cooling_infiltration: z.number().nullish(),
        cooling_insulation: z.number().nullish(),
        cooling_radiation: z.number().nullish(),
    }),
})
export type ElectrificationData = z.infer<typeof ElectrificationDataSchema>
export const FullAssessmentSchema = z.object({
    analysis: AnalysisDataSchema.optional(),
    electrification: ElectrificationDataSchema.optional(),
})
export type FullAssessmentData = z.infer<typeof FullAssessmentSchema>


export const AssessmentFilesEnumSchema = z.enum([
    //Home Walkthrough images
    'window_image', 'insulation_image', 'electric_panel_image',
    'crawlspace_image', 'primary_cooling_image', 'secondary_cooling_image',
    'primary_heating_image', 'secondary_heating_image', 'water_heater_image',
    'stove_image', 'siding_image',
    //unclassified home walkthrough images
    'unclassified_image',
    //home walkthrough video
    'capture_video',
    //from cubicasa
    'capture_rendered_floor_plan', 'capture_rendering_raw',
    //bim team created
    'capture_rendering_cleaned', 'capture_rendering_enhanced',
    //from autodesk
    'capture_rendering_enhanced.svf', 'capture_rendering_enhanced.thumbnail',
])
export type AssessmentFilesEnum = z.infer<typeof AssessmentFilesEnumSchema>

export const AssessmentFileSchema = z.object({
    file_id: z.string(),
    type: AssessmentFilesEnumSchema,
    name: z.string(),
    s3_url: z.string().url(),
    created_by: z.string(),
    created_date: z.string().datetime(),
    metadata: z.string().nullish()
})
export const AssessmentFilesSchema = z.record(z.string(), AssessmentFileSchema)
//
// export const OldAssessmentFileSchema = z.object({
//     name: z.string(),
//     s3_url: z.string().url(),
//     created_by: z.string(),
//     created_date: z.string().datetime(),
//     metadata: z.string().nullish()
// })
// export const OldAssessmentFilesSchema = z.record(AssessmentFilesEnumSchema, z.array(OldAssessmentFileSchema).optional())
//
// export type OldAssessmentFiles = z.infer<typeof OldAssessmentFilesSchema>
// export type OldAssessmentFile = z.infer<typeof OldAssessmentFileSchema>
export type AssessmentFiles = z.infer<typeof AssessmentFilesSchema>
export type AssessmentFile = z.infer<typeof AssessmentFileSchema>
export const AssessmentNoteSchema = z.object({
    message: z.string(),
    created_by: z.string(),
    created_date: z.string().datetime()
})
export type AssessmentNote = z.infer<typeof AssessmentNoteSchema>


const SolarCostSchema = z.object({
    currencyCode: z.string(),
    units: z.string().optional(),
    nanos: z.number().optional()
})
const SolarSavingsSchema = z.object({
    savingsYear1: SolarCostSchema,
    savingsYear20: SolarCostSchema,
    presentValueOfSavingsYear20: SolarCostSchema,
    financiallyViable: z.boolean().nullish(),
    savingsLifetime: SolarCostSchema,
    presentValueOfSavingsLifetime: SolarCostSchema
})

/*this is just a partial schema confiriming the data that the rscript currently uses*/
const SolarFinancialAnalysisSchema = z.object({
    monthlyBill: SolarCostSchema.optional(),
    panelConfigIndex: z.number(),
    financialDetails: z.object({
        initialAcKwhPerYear: z.number().optional(),
        remainingLifetimeUtilityBill: SolarCostSchema.optional(),
        federalIncentive: SolarCostSchema.optional(),
        stateIncentive: SolarCostSchema.optional(),
        utilityIncentive: SolarCostSchema.optional(),
        lifetimeSrecTotal: SolarCostSchema.optional(),
        costOfElectricityWithoutSolar: SolarCostSchema.optional(),
        netMeteringAllowed: z.boolean().optional(),
        solarPercentage: z.number().optional(),
        percentageExportedToGrid: z.number().optional()
    }).optional(),
    leasingSavings: z.object({
        leasesAllowed: z.boolean().optional(),
        leasesSupported: z.boolean().optional(),
        annualLeasingCost: SolarCostSchema.optional(),
        savings: SolarSavingsSchema.optional()
    }).optional(),
    cashPurchaseSavings: z.object({
        outOfPocketCost: SolarCostSchema,
        upfrontCost: SolarCostSchema,
        rebateValue: SolarCostSchema,
        paybackYears: z.number(),
        savings: SolarSavingsSchema
    }).optional(),
    financedPurchaseSavings: z.object({
        annualLoanPayment: SolarCostSchema.optional(),
        rebateValue: SolarCostSchema.optional(),
        loanInterestRate: z.number().optional(),
        savings: SolarSavingsSchema.optional()
    }).optional()
})
export type SolarFinancialAnalysis = z.infer<typeof SolarFinancialAnalysisSchema>
export const SolarPotentialSchema = z.object({
    maxArrayPanelsCount: z.number().optional(),
    maxArrayAreaMeters2: z.number().optional(),
    maxSunshineHoursPerYear: z.number(),
    carbonOffsetFactorKgPerMwh: z.number().optional(),
    wholeRoofStats: z.object({}).optional(),
    financialAnalyses: z.array(SolarFinancialAnalysisSchema),
    solarPanelConfigs: z.array(z.object({
        panelsCount: z.number().optional(),
        yearlyEnergyDcKwh: z.number().optional(),
        roofSegmentSummaries: z.array(z.object({
            pitchDegrees: z.number(),
            azimuthDegrees: z.number(),
            panelsCount: z.number(),
            yearlyEnergyDcKwh: z.number(),
            segmentIndex: z.number()
        })).optional()
    }))
})
export type SolarPotential = z.infer<typeof SolarPotentialSchema>
export const AirSensorDataSchema = z.object({
    device_id: z.string(),
    datetime: z.string().datetime(),
    datetime_local: z.string().datetime(),
    hour: z.number(),
    sensors_temp: z.number(),
    sensors_radon: z.number().optional(),
    sensors_pressure: z.number().optional(),
    sensors_pm25: z.number().optional(),
    sensors_voc: z.number().optional(),
    sensors_lux: z.number().optional(),
    sensors_co2: z.number(),
    sensors_humid: z.number().optional(),
})
export type AirSensorData = z.infer<typeof AirSensorDataSchema>
export const RecDataSchema = RecommendationFinancialsSchema
    .merge(z.object({Prerequisites: z.array(z.string()).optional()}))

export type RecData = z.infer<typeof RecDataSchema>


export const EnergyDataRecordSchema = z.object({
    date: z.string()/*.date()*/,//TODO rename to end_date
    num_days: z.number().optional(),//TODO change to not optional
    usage: z.number(),
    cost: z.number(),
    estimated: z.boolean().nullish(),
})

export type EnergyDataRecord = z.infer<typeof EnergyDataRecordSchema>
export const AssessmentRunResultSchema = z.array(z.string())
export type AssessmentRunResult = z.infer<typeof AssessmentRunResultSchema>
export const AddressSchema = z.object({
    address1: z.string(),
    address2: z.string().nullish(),
    neighborhood: z.string().nullish(),
    city: z.string(),
    county: z.string().nullish(),
    state: z.string(),
    countryCode: z.string(),
    postalCode: z.string(),
    address_aliases: z.array(z.string()).nullish()
}).openapi("Address")
export type Address = z.infer<typeof AddressSchema>

export const DataCollectionEnumSchema = z.enum(['collect-optional', 'collect-mandatory', 'skip', 'opted-out'])
export type DataCollectionEnum = z.infer<typeof DataCollectionEnumSchema>
const AssessmentDataCollectionKeys = [...AssessmentFilesEnumSchema.options.map(o=>`assessment_files/${o}`),'electric_usage','gas_usage','assessment_meeting']
export const AssessmentFilesDataCollectionEnum = z.enum(AssessmentDataCollectionKeys as [string, ...string[]])

export const AssessmentDataCollectionConfigurationSchema = z.record(AssessmentFilesDataCollectionEnum,DataCollectionEnumSchema)
export type AssessmentDataCollectionConfiguration = z.infer<typeof AssessmentDataCollectionConfigurationSchema>
export const BASELINE_DATA_COLLECTION_REQUIREMENTS: AssessmentDataCollectionConfiguration = {
    electric_usage: 'collect-optional',//setting to optional while piers is running most assessments
    gas_usage: 'collect-optional',//setting to optional while piers is running most assessments
    assessment_meeting: 'collect-optional',//setting to optional while piers is running most assessments
    'assessment_files/window_image': 'collect-optional',//setting to optional while piers is running most assessments
    'assessment_files/electric_panel_image': 'collect-optional',//setting to optional while piers is running most assessments
    'assessment_files/primary_heating_image': 'collect-optional',//setting to optional while piers is running most assessments
    'assessment_files/water_heater_image': 'collect-optional',//setting to optional while piers is running most assessments
    'assessment_files/capture_video': 'collect-optional', //setting to optional while piers is running most assessments
    'assessment_files/insulation_image': 'collect-optional', //attic may not be accessible
    'assessment_files/crawlspace_image': 'collect-optional', //crawspace may not exist or be accessible
    'assessment_files/primary_cooling_image': 'collect-optional', //may not exist
    'assessment_files/siding_image': 'collect-optional', //may not exist
    // 'assessment_files/secondary_cooling_image': 'collect-optional', //may not exist, and don't even want to prompt if missing
    // 'assessment_files/secondary_heating_image': 'collect-optional',
}

export const UserRolesSchema = z.enum(['admin','contractor-admin','contractor','homeowner'])
export type UserRole = z.infer<typeof UserRolesSchema>

export const ProjectEventEnumSchema = z.enum(["install"])
export const ProjectEventSchema = z.object({
    date:z.string(),
    type:ProjectEventEnumSchema,
    calendarId:z.string(),
    eventId:z.string()
})
export const ProjectEventsSchema = z.record(z.string(),ProjectEventSchema)
export type ProjectEventEnum = z.infer<typeof ProjectEventEnumSchema>
export type ProjectEvents = z.infer<typeof ProjectEventsSchema>