import {z} from "zod";
import {extendZodWithOpenApi} from '@asteasolutions/zod-to-openapi';
import {
    AssessmentSchema,
    HomeSchema,
    GetRecommendationsSchema,
    GetSharesSchema,
    SeeAirUserSchema, ProjectAggregateSchema
} from "./refined-tables.js";
import {
    AssessmentFileSchema,
    RecDataSchema, RecommendationDiscountSchema,
    RecommendationFinancialsSchema, RecommendationLineItemSchema
} from "./jsonb-schemas.js";
import {Message} from "@aws-sdk/client-sqs";



extendZodWithOpenApi(z);

export const KnownHomeDetailsKeys = {
    Beds: 'beds',
    Baths: 'baths',
    Sqft: 'sqft',
    Floors: 'floors',
    Cooling: 'cooling',
    Heating: 'heating',
} as const



extendZodWithOpenApi(z);

export type RRecommendationData = z.infer<typeof RecommendationFinancialsSchema>
export const RecInputSchema = z.object({
    original_rec_id: z.string(),
    title: z.string(),
    description: z.string().nullish(),
    category: z.string(),
    type: z.string(),
    rec_data: RecDataSchema,
    discounts:z.array(RecommendationDiscountSchema),
    line_items:z.array(RecommendationLineItemSchema)
})
export type RecInputData = z.infer<typeof RecInputSchema>
export const RecManualInputDataSchema = RecInputSchema.merge(z.object({
    category: z.string().optional(),
    type: z.string().optional(),
}))

export type RecManualInputData = z.infer<typeof RecManualInputDataSchema>


export type LocationAssessment = {
    locationName: string
    infiltration: number
    insulation: number
    electrification: number
}


export const AwairDataSchema = z.record(z.string(), z.array(z.any()))


// export const UserSchema = z.object({
//     email: z.string(),
//     id: z.string(),
//     name: z.string().nullish(),
//     image: z.string().nullish(),
//     role: UserRolesSchema.nullish(),
// })
//
// export type User = z.infer<typeof UserSchema>

export const RentcastPropertiesSchema = z.object({
    // formattedAddress:z.string(),
    // addressLine1:z.string(),
    // addressLine2:z.string().nullish(),
    // city:z.string(),
    // state:z.string(),
    // zipCode:z.string(),
    // county:z.string(),
    // latitude:z.number(),
    // longitude:z.string(),
    bedrooms: z.number().optional(),
    bathrooms: z.number().optional(),
    yearBuilt: z.number().optional(),
    squareFootage: z.number().optional(),
    features: z.object({
        fireplace: z.boolean().optional(),
        floorCount: z.number().optional(),
        // heating:z.boolean(),
        // cooling:z.boolean(),
        // roofType:z.string(),
        // coolingType:z.string(),
        // heatingType:z.string(),
    }).optional()
})
export type RentcastProperties = z.infer<typeof RentcastPropertiesSchema>


export const RecommendationTemplateSchema = z.object({
    id: z.string(),
    title: z.string(),
    icon: z.string().nullish(),
    deal: z.enum(["No Deal", "Deal"]),
    type: z.enum(["DIY", "PRO"]),
    healthCategory: z.number().max(3),
    climateCategory: z.number().max(3),
    priceCategory: z.number().max(3),
    category: z.enum(["Electrification", "Insulation", "Infiltration"]),
    description: z.string().nullish(),
    healthSummary: z.string().nullish(),
    climateSummary: z.string().nullish(),
    priceSummary: z.string().nullish(),
    healthDescription: z.string().nullish(),
    climateDescription: z.string().nullish(),
    priceDescription: z.string().nullish(),
    prerequisites: z.array(z.string()),
    projectButtonText: z.string().nullish(),
    projectImageUrl: z.string().nullish(),
    projectUrl: z.string().nullish(),
    termsDocId: z.string().nullish(),
})
export type RecommendationTemplate = z.infer<typeof RecommendationTemplateSchema>
export const FileUploadNonceSchema = z.object({
    assessment_id: z.string(),
    fileRecord: AssessmentFileSchema.omit({file_id:true})
})
export type FileUploadNonce = z.infer<typeof FileUploadNonceSchema>
export const FileUpdateNonceSchema = z.object({
    assessment_id: z.string(),
    fileRecord: AssessmentFileSchema
})
export type FileUpdateNonce = z.infer<typeof FileUpdateNonceSchema>
export const FileDeleteNonceSchema = z.object({
    assessment_id: z.string(),
    file_id: z.string()
})
export type FileDeleteNonce = z.infer<typeof FileDeleteNonceSchema>

export const AuthzRolesSchema = z.enum(['site_admin', 'owner', 'write', 'read'])
export type AuthzRoles = z.infer<typeof AuthzRolesSchema>

export const AssessmentUpdateEventSchema = z.enum([
    "file-saved",
    "meeting-saved",
    "meeting-removed",
    "note-saved",
    "home-details-updated",
    "energy-data-saved",
    "assessment-data-updated",
    "solar-data-saved",
    "rec-added",
    "rec-deleted",
    "rec-updated",
    "status-changed",
    "data-requirements-changed",
    "project-deleted",
])
export type AssessmentUpdateEvent = z.infer<typeof AssessmentUpdateEventSchema>

export const ExternalSyncRecordSchema = z.object({
    event: AssessmentUpdateEventSchema,
    assessment_id: z.string(),
})
export type ExternalSyncRecord = z.infer<typeof ExternalSyncRecordSchema>




export const RecommendationAggregateSchema = GetRecommendationsSchema
    .omit({rec_data: true})
    .merge(z.object({rec_data: RecDataSchema}))
    .openapi("recommendation")
export type RecommendationAggregate = z.infer<typeof RecommendationAggregateSchema>
export const HomeOwnerSchema = SeeAirUserSchema.pick({
    name: true,
    email: true,
})
export type HomeOwner = z.infer<typeof HomeOwnerSchema>
export const HomeAggregateSchema = HomeSchema.merge(z.object({
    owner: HomeOwnerSchema,
    shares: z.array(GetSharesSchema.openapi("share")),
    assessments: z.array(AssessmentSchema.openapi("assessment")),
    /* for the life of me i can't figure out why we need to omit, then re-merge `rec_data` if we don't to this it shows up as a ZodLazy (with incorrect fields) to our code generator*/
    recommendations: z.array(RecommendationAggregateSchema).openapi("recommendations"),
    projects: z.array(ProjectAggregateSchema.openapi("projects")),
    permissions: z.set(AuthzRolesSchema).openapi("permissions", {type: "object"})
})).openapi("home")
export type HomeAggregate = z.infer<typeof HomeAggregateSchema>


export const AdminAssessmentSummarySchema = SeeAirUserSchema.merge(z.object({
    home: HomeSchema,
    assessment: AssessmentSchema
}))
export type AdminAssessmentSummary = z.infer<typeof AdminAssessmentSummarySchema>

export const AssessmentThresholdsSchema = z.record(
    z.string(),
    z.array(z.object({color: z.string(), simpleCondition: z.string()}))
)
export type AssessmentThresholds = z.infer<typeof AssessmentThresholdsSchema>
export const HomeDetailsFieldInfoSchema = z.object({
    type: z.string(),
    id: z.string(),
    title: z.string(),
    default: z.string().optional(),
    options: z.array(z.string())
})
export type HomeDetailsFieldInfo = z.infer<typeof HomeDetailsFieldInfoSchema>



export const AddressComponentTypeSchema = z.enum(["subpremise","street_number", "route", "neighborhood", "locality", "administrative_area_level_2", "administrative_area_level_1", "country", "postal_code", "political"])
export type AddressComponentType = z.infer<typeof AddressComponentTypeSchema>
export const PlacesAddressSchema = z.object({
    formattedAddress: z.string(),
    location: z.object({
        latitude: z.number(),
        longitude: z.number(),
    }),
    addressComponents: z.array(z.object({
        shortText: z.string(),
        types: z.array(z.string()) //this should be AddressComponentTypeSchema, but we don't want to fail if we get unexpected options
    }))
})
export type PlacesAddress = z.infer<typeof PlacesAddressSchema>
export const NormalizedAddressSchema = z.object({
    normalizedComparable: z.string()
}).merge(PlacesAddressSchema)
export type NormalizedAddress = z.infer<typeof NormalizedAddressSchema>





export type Sheet = Array<Array<string>>


export const Dynamic3dViewerConfigSchema = z.object({
    buildingNodeNames:z.array(z.string()),
    hideToolbar:z.boolean()
})
export type Dynamic3dViewerConfig = z.infer<typeof Dynamic3dViewerConfigSchema>


export type ProformaCost = { type: 'range', high: number, low: number } | { type: 'single', cost: number }
// export type ProformaDiscount = ProformaCost | { type:'percent', percentage:number,cap:number}
export type ProformaPayback = { type: 'range', high: number, low: number } | { type: 'single', years: number }
export type ProformaIncentive = {
    title: string,
    description?: string,
    timeOfPurchase: boolean,
    cost: ProformaCost,
    url?: string,
    recNumbers: Array<string>
}
export type CostedProformaIncentive = ProformaIncentive & {cost:{ type: 'single', cost: number }}
export type ProformaItem = {
    title: string,
    price_per_unit: number,
    quantity: number,
    recNumbers: Array<string>
}
export type ProformaData = {
    lineItems: Array<ProformaItem>
    gross: ProformaCost
    incentives: Array<ProformaIncentive>
    annual_savings: ProformaCost
}


export type LocationId = 'MA' | 'CA'

export type NormalizedEnergyUsageMonth = {date: number,electric_usage: number,electric_cost: number,gas_usage?:number,gas_cost?:number,oil_usage?:number,oil_cost?:number}
export type NormalizedEnergyUsage = {[firstOfMonth:string]:NormalizedEnergyUsageMonth}




export const AdminTabSchema = z.enum([
    'address','home_details','assessment_files','utilities','recommendations','notes','solar','projects','permissions'
])
export type AdminTab = z.infer<typeof AdminTabSchema>
export const AdminAssessmentPageSearchSchema = z.object({tab:AdminTabSchema,selected:z.string().optional()})
export type AdminAssessmentPageSearch = z.infer<typeof AdminAssessmentPageSearchSchema>
export const HomePageSearchSchema = z.object({viewer:z.boolean().optional(),project:z.string().optional()})
export type HomePageSearch = z.infer<typeof HomePageSearchSchema>

export type DlqMessage = Message & {QueueUrl:string}