import {
    AbsoluteCenterStack,
    Badge,
    DeleteWidget,
    DesignedIconButton,
    EditableCalendar,
    getUseMutationOpt,
    HStack,
    LineItemTable,
    ProjectIcon,
    ProjectIcons,
    RecommendationsAccordion,
    Text2Xl,
    TextBase,
    TextLg,
    TextSm,
    trpc,
    VStack
} from "@seeair/shared-components";
import {Assessment, HomeAggregate, ProjectAggregate} from "@seeair/schemas";
import {Accordion, Loader, Menu, Modal, Tabs, TextInput} from "@mantine/core";
import {RecControl} from './AdminRecommendationsPanel.js';
import {
    IconCancel,
    IconCloudDollar,
    IconCloudPlus,
    IconCloudStar,
    IconDeviceFloppy,
    IconEye,
    IconPencil,
    IconPlus,
    IconRowInsertBottom
} from "@tabler/icons-react";
import React, {useEffect, useState} from "react";
import {calculateDealAmount, getRecNumbersForProject, PROJECT_TYPE_ISM} from "@seeair/shared";

export function AdminProjectsPanel({home, assessment, selected}: {
    home: HomeAggregate,
    assessment: Assessment,
    selected?: string
}) {
    const [previewProject, setPreviewProject] = useState(null as ProjectAggregate | null)
    const [project_title, setProjectTitle] = useState("")
    const {
        isPending: isCreateProjectPending,
        mutate: createProject
    } = trpc.ADMIN.createProject.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack>
        <ProjectAccordion home={home} setPreviewProject={setPreviewProject} selected={selected}
                          assessment={assessment}/>
        <HStack leftCenter classNames="mt-8">
            <TextSm>New Project</TextSm>
            <TextInput
                classNames={{input: "w-72", root: "mx-4"}}
                label="Project Title"
                value={project_title}
                onChange={(event) =>
                    setProjectTitle(event.currentTarget.value)}/>
            <DesignedIconButton
                tooltip="Create Project"
                disabled={isCreateProjectPending || project_title.length < 1}
                onClick={() => createProject({home_id: home.home_id, project_title})}
                icon={<IconPlus/>}
            />
        </HStack>
        <Modal title={previewProject?.project_title} fullScreen opened={!!previewProject}
               onClose={() => setPreviewProject(null)}>
            {previewProject &&
                <RecommendationsAccordion projects={[previewProject!]} recs={previewProject.recommendations}
                                          filteredRecs={[]} includeProjects={true}/>}
        </Modal>
    </VStack>
}

function ProjectAccordion({home, setPreviewProject, selected, assessment}: {
    home: HomeAggregate,
    setPreviewProject: (p: ProjectAggregate) => void,
    selected?: string,
    assessment: Assessment
}) {
    const [openProjectId, setOpenProjectId] = useState((home.projects ?? [])[0]?.project_id as string | null)
    useEffect(() => {
        if ((home.projects ?? []).map(p => p.project_id).includes(selected ?? "")) {
            setOpenProjectId(selected!)
        }
    }, [home.projects, selected])
    return <Tabs
        value={openProjectId}
        onChange={setOpenProjectId}
        orientation='vertical'
        classNames={{tab: `border border-gray-500 aria-selected:bg-gray-200`}}
    >
        <Tabs.List>
            {
                (home.projects ?? []).map(p => <AdminProjectControl key={p.project_id} project={p}
                                                               setPreviewProject={setPreviewProject}/>)
            }
        </Tabs.List>
        {(home.projects ?? []).map((p) => <Tabs.Panel key={p.project_id} value={p.project_id}>
            <AdminProjectPanel project={p} home={home} assessment={assessment}/>
        </Tabs.Panel>)}
    </Tabs>
}

function AdminProjectControl({project, setPreviewProject}: {
    project: ProjectAggregate,
    setPreviewProject: (p: ProjectAggregate) => void
}) {
    return <Tabs.Tab
        className={"justify-between"}
        value={project.project_id}
        leftSection={<ProjectIcons sm rec_numbers={project.recommendations.map(r => r.original_rec_id)}/>}
        rightSection={<DesignedIconButton
            tooltip="Preview For Homeowner"
            disabled={false}
            icon={<IconEye/>}
            stopPropagation
            onClick={() => {
                setPreviewProject(project)
            }}/>}
    >
        {project.project_title}
    </Tabs.Tab>
}

export function DeleteProjectWidget({project}: { project: ProjectAggregate }) {
    const {mutate: deleteProject} = trpc.ADMIN.deleteProject.useMutation(getUseMutationOpt(trpc.useUtils()))
    const {mutate: renameProject} = trpc.ADMIN.renameProject.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <DeleteWidget
        name={`${project.project_title}`}
        deleteTitle={`Delete Project (${getRecNumbersForProject(project) || "none"})`}
        deleteFn={() => {
            deleteProject({project_id: project.project_id})
        }}
        renameTitle={`Rename Project`}
        renameFn={(name) => {
            renameProject({project_id: project.project_id, project_title: name})
        }}/>
}

export function AdminProjectPanel({project, home, assessment}: {
    project: ProjectAggregate,
    home: HomeAggregate,
    assessment: Assessment
}) {
    return <VStack>
        <Text2Xl center>{project.project_title}</Text2Xl>
        <Accordion defaultValue="recs" variant={"contained"}>
            <ProjectPanelRecsSection project={project} assessment={assessment} home={home}/>
            <ProjectPanelHubspotSection project={project}/>
            <ProjectPanelDatesSection project={project}/>
        </Accordion>
    </VStack>
}

function ProjectPanelRecsSection({project, assessment, home}: {
    project: ProjectAggregate,
    assessment: Assessment,
    home: HomeAggregate
}) {
    const {
        isPending: isSetProjectPending,
        mutate: setProject
    } = trpc.ADMIN.setProjectForRecommendation.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <Accordion.Item value="recs">
        <Accordion.Control>
            <TextLg>Recommendations</TextLg>
        </Accordion.Control>
        <Accordion.Panel>
            <VStack>
                <Menu>
                    <Menu.Target>
                        <div>
                            <DesignedIconButton
                                tooltip="Add recommendation to project"
                                icon={isSetProjectPending ? <Loader size="sm"/> : <IconRowInsertBottom/>}
                                disabled={isSetProjectPending}
                                stopPropagation
                                onClick={() => {
                                }}
                            />
                        </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Label>Ungrouped Recommendations</Menu.Label>
                        {(home.recommendations ?? []).filter(r => !r.project_id).map(r =>
                            <Menu.Item
                                key={r.recommendation_id}
                                leftSection={<ProjectIcon sm rec_number={r.original_rec_id}/>}
                                onClick={() => setProject({
                                    home_id: project.home_id,
                                    recommendation_id: r.recommendation_id,
                                    project_id: project.project_id,
                                    selected: true
                                })}
                            >
                                {r.title}
                            </Menu.Item>)}
                    </Menu.Dropdown>
                </Menu>
                {(project.recommendations ?? []).map(r => <HStack key={r.recommendation_id}>
                    <RecControl rec={r} proj={project} assessment={assessment} recLink={true} projLink={false}
                                col={false}/>
                </HStack>)}
            </VStack>
        </Accordion.Panel>
    </Accordion.Item>
}

function ProjectPanelDatesSection({project}: { project: ProjectAggregate }) {
    const [crewEmails, setCrewEmails] = useState((project.project_crew ?? []).join(","))
    const [editingCrew, setEditingCrew] = useState(false)
    const {
        isPending: isSetHomeownerAvailabilityPending,
        isSuccess: isSetHomeownerAvailabilitySuccess,
        mutate: setHomeownerAvailability
    } = trpc.HOMEOWNER.setHomeownerAvailabilityForProject.useMutation(getUseMutationOpt(trpc.useUtils()))
    const {
        isPending: isSetScheduledDatesPending,
        isSuccess: isSetScheduledDatesSuccess,
        mutate: setScheduledDates
    } = trpc.ADMIN.setScheduledDatesForProject.useMutation(getUseMutationOpt(trpc.useUtils()))
    const {
        isPending: isSetCrewPending,
        mutate: mutateCrew
    } = trpc.ADMIN.setProjectCrew.useMutation(getUseMutationOpt(trpc.useUtils(), () => setEditingCrew(false)))
    return <Accordion.Item value="dates">
        <Accordion.Control>
            <HStack leftCenter>
                <TextLg>Dates</TextLg>
                {(project.homeowner_availability ?? [])?.length > 0 &&
                    <Badge classNames="mx-2">Homeowner Availability</Badge>}
                {Object.keys(project.scheduled_dates??{}).length > 0 &&
                    <Badge classNames="mx-2">Scheduled</Badge>}
            </HStack>
        </Accordion.Control>
        <Accordion.Panel>
            <VStack>
                <HStack>
                    <TextInput
                        label="Crew Emails"
                        disabled={!editingCrew}
                        value={crewEmails}
                        onChange={(e) => setCrewEmails(e.currentTarget.value)}
                        placeholder={"Comma Separated Emails"}
                    />
                    <DesignedIconButton disabled={isSetCrewPending}
                                        icon={editingCrew ? <IconDeviceFloppy/> : <IconPencil/>} onClick={() => {
                        if (editingCrew) {
                            mutateCrew({project_id: project.project_id, project_crew: crewEmails.split(',')})
                        } else {
                            setEditingCrew(true)
                        }
                    }} tooltip={editingCrew ? "Save" : "Edit"}/>
                    {editingCrew && <DesignedIconButton disabled={isSetCrewPending} icon={<IconCancel/>}
                                                        onClick={() => setEditingCrew(false)} tooltip={"Cancel"}/>}
                </HStack>

                <EditableCalendar
                    alwaysEditing={false}
                    shouldDisableDay={(_) => ""}
                    title={"Homeowner Availability"}
                    initialValue={project.homeowner_availability ?? []}
                    isPending={isSetHomeownerAvailabilityPending}
                    isSuccess={isSetHomeownerAvailabilitySuccess}
                    save={(dates) => setHomeownerAvailability({
                        home_id: project.home_id,
                        project_id: project.project_id,
                        homeowner_availability: dates
                    })}
                />
                <EditableCalendar
                    alwaysEditing={false}
                    shouldDisableDay={(d) => {
                        if (!project.homeowner_availability?.includes(d)) {
                            return "Homeowner Unavailable"
                        }
                        return ""
                    }}
                    title={"Scheduled Dates"}
                    initialValue={Object.keys(project.scheduled_dates ?? {})}
                    isPending={isSetScheduledDatesPending}
                    isSuccess={isSetScheduledDatesSuccess}
                    save={(dates) => setScheduledDates({project_id: project.project_id, scheduled_dates: dates})}
                />
            </VStack>
        </Accordion.Panel>
    </Accordion.Item>
}

function ProjectPanelHubspotSection({project}: { project: ProjectAggregate }) {
    const [syncModalOpen, setSyncModalOpen] = useState(false)
    const [quoteModalOpen, setQuoteModalOpen] = useState(false)
    const {
        isPending: isSyncToHubspotPending,
        mutate: syncToHubspot
    } = trpc.ADMIN.pushProjectLineItemsToHubspotDeal.useMutation(getUseMutationOpt(trpc.useUtils(), () => setSyncModalOpen(false)))
    const {
        isPending: isCreateQuotePending,
        mutate: createQuote
    } = trpc.ADMIN.createHubspotQuoteFromProject.useMutation(getUseMutationOpt(trpc.useUtils(), () => setQuoteModalOpen(false)))
    return <Accordion.Item value="hubspot">
        <Accordion.Control>
            <HStack leftCenter>
                <TextLg center>Hubspot</TextLg>
                {project.hubspot_deal_stage &&
                    <Badge classNames="mx-2">Stage {project.hubspot_deal_stage}</Badge>}
                {project.hubspot_quote_status &&
                    <Badge classNames="mx-2">Quote {project.hubspot_quote_status}</Badge>}
                {project.hubspot_quote_signature_status &&
                    <Badge classNames="mx-2">Signature {project.hubspot_quote_signature_status}</Badge>}
                {project.hubspot_quote_payment_status &&
                    <Badge classNames="mx-2">Payment {project.hubspot_quote_payment_status}</Badge>}
            </HStack>
        </Accordion.Control>
        <Accordion.Panel>
            <HStack between>
                <HStack leftCenter>
                    <DesignedIconButton
                        tooltip="Sync LineItems to Hubspot Deal"
                        disabled={isSyncToHubspotPending || project.project_type == PROJECT_TYPE_ISM}
                        icon={<IconCloudStar/>}
                        onClick={() => {
                            setSyncModalOpen(true)
                        }}/>
                    <DesignedIconButton
                        tooltip="Create Hubspot Quote"
                        disabled={isSyncToHubspotPending || project.project_type == PROJECT_TYPE_ISM}
                        icon={<IconCloudDollar/>}
                        onClick={() => {
                            setQuoteModalOpen(true)
                        }}/>

                    {project.hubspot_deal_id && <a className="mx-2"
                                                   href={`https://app.hubspot.com/contacts/46365478/record/0-3/${project.hubspot_deal_id}`}
                                                   target="_blank" rel="noreferrer">Hubspot Deal Link</a>}
                    {project.hubspot_quote_url &&
                        <a className="mx-2" href={project.hubspot_quote_url} target="_blank" rel="noreferrer">Hubspot
                            Quote URL</a>}

                </HStack>
                <DeleteProjectWidget project={project}/>
            </HStack>

            <TextLg medium>Deal Line Items</TextLg>
            <LineItemTable line_items={project.hubspot_deal_line_items ?? []}/>
            <TextLg>Deal Amount {project.hubspot_deal_amount}</TextLg>
            <TextLg medium>Quote Line Items</TextLg>
            <LineItemTable line_items={project.hubspot_quote_line_items ?? []}/>

            <TextLg>Quote Amount {project.hubspot_quote_amount}</TextLg>
            <TextSm>{project.hubspot_last_modified}</TextSm>
            <Modal title="Sync To Hubspot"
                   opened={syncModalOpen}
                   onClose={() => {
                       setSyncModalOpen(false)
                   }}>
                <VStack>
                    <TextBase>Replace {project.hubspot_deal_line_items?.length ?? 0} Deal LineItems</TextBase>
                    <TextBase>Change Deal Amount
                        from {project.hubspot_deal_amount ?? "undefined"} to {calculateDealAmount(project)}</TextBase>
                    {isSyncToHubspotPending && <AbsoluteCenterStack><Loader/></AbsoluteCenterStack>}
                    <HStack between center classNames="mt-16">
                        <DesignedIconButton disabled={isSyncToHubspotPending} icon={<IconCloudStar/>} onClick={() => {
                            syncToHubspot({project_id: project.project_id})
                        }} tooltip="Sync to Hubspot"/>
                        <DesignedIconButton disabled={false} icon={<IconCancel/>}
                                            onClick={() => setSyncModalOpen(false)} tooltip={"Cancel"}/>
                    </HStack>
                </VStack>
            </Modal>
            <Modal title="Create Hubspot Quote"
                   opened={quoteModalOpen}
                   onClose={() => {
                       setQuoteModalOpen(false)
                   }}>
                <VStack>
                    <TextBase>Create Quote</TextBase>
                    {isCreateQuotePending && <AbsoluteCenterStack><Loader/></AbsoluteCenterStack>}
                    <HStack between center classNames="mt-16">
                        <DesignedIconButton disabled={isCreateQuotePending} icon={<IconCloudPlus/>} onClick={() => {
                            createQuote({project_id: project.project_id})
                        }} tooltip="Create Quote"/>
                        <DesignedIconButton disabled={false} icon={<IconCancel/>}
                                            onClick={() => setSyncModalOpen(false)} tooltip={"Cancel"}/>
                    </HStack>
                </VStack>
            </Modal>
        </Accordion.Panel>
    </Accordion.Item>
}