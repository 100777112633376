import {Table} from "@mantine/core";
import {HomeDetailsSchema, AdminAssessmentSummary} from "@seeair/schemas";
import {buildShortAddress, truncateString} from "@seeair/shared";
import {Link} from "@tanstack/react-router";
import dayjs from "dayjs";
import React from "react";

export function AdminAssessmentsTable({summaries}:{summaries:Array<AdminAssessmentSummary>}) {
    return <Table striped highlightOnHover withTableBorder>
        <Table.Thead>
            <Table.Tr>
                <Table.Th>Email</Table.Th>
                <Table.Th>Address</Table.Th>
                <Table.Th>Status</Table.Th>
                <Table.Th>Admin</Table.Th>
                <Table.Th>Homeowner</Table.Th>
                <Table.Th>Contractor</Table.Th>
                <Table.Th>Home Details<br/>(of {Object.keys(HomeDetailsSchema.shape).length})</Table.Th>
                <Table.Th>Files</Table.Th>
                <Table.Th>Modified</Table.Th>
            </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
            {summaries.map(s => {
                return <Table.Tr key={s.home.home_id}>
                    <Table.Td>{s?.email}</Table.Td>
                    <Table.Td>{buildShortAddress(s.home.address)}</Table.Td>
                    <Table.Td>{s.assessment.assessment_status}</Table.Td>
                    <Table.Td>
                        <Link
                            to="/admin/assessment/$assessment_id"
                            search={{tab: 'home_details'}}
                            params={{
                                assessment_id: s.assessment.assessment_id
                            }}
                        >{truncateString(s.assessment.assessment_id,10)}</Link>
                    </Table.Td>
                    <Table.Td>
                        <Link
                            to="/homes/$home_id"
                            params={{
                                home_id: s.home.home_id
                            }}
                        >{truncateString(s.home.home_id,10)}</Link>
                    </Table.Td>
                    <Table.Td>
                        <Link
                            to="/contractor/home/$home_id"
                            params={{
                                home_id: s.home.home_id
                            }}
                        >{truncateString(s.home.home_id,10)}</Link>
                    </Table.Td>
                    <Table.Td>{Object.values(s.assessment.home_details ?? {}).filter(o => o).length}</Table.Td>
                    <Table.Td>{Object.values(s.assessment.assessment_files_list ?? {}).length}</Table.Td>
                    <Table.Td>{dayjs(s.home.aggregate_last_modified).format()}</Table.Td>
                </Table.Tr>
            })
            }
        </Table.Tbody>
    </Table>
}