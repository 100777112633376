import { COLOR_WHITE} from './Theme.js';
import {FullWithDesignedSection, ProjectIcons, TextBase, VStack} from './DesignBase.js';
import React from 'react'
import { Indicator, Table, Tabs, Tooltip} from "@mantine/core";
import {Link} from "@tanstack/react-router";
import {
    buildExtraShortAddress,
    buildShortAddress,
    dateIsToday,
    isoStringArrayContainsDate,
    PROJECT_TYPE_ISM
} from "@seeair/shared";
import {ContractorHome, ContractorProject} from "@seeair/schemas";
import {Calendar} from "@mantine/dates";
import {IconCalendar, IconHome} from "@tabler/icons-react";
import {DoubleLineBadge} from './DesignComponents.js';
import classNames from "classnames";

export function ContractorDashboard({homes}:{homes:Array<ContractorHome>}) {
    return <FullWithDesignedSection background={COLOR_WHITE}>
        <Tabs defaultValue="calendar">
            <Tabs.List>
                <Tabs.Tab value="calendar" leftSection={<IconCalendar />}>Calendar</Tabs.Tab>
                <Tabs.Tab value="homes" leftSection={<IconHome />}>Homes</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="calendar">
                <ContractorCalendar homes={homes} />
            </Tabs.Panel>
            <Tabs.Panel value="homes">
                <AdminHomesTable homes={homes} />
            </Tabs.Panel>
        </Tabs>
    </FullWithDesignedSection>
}
function ContractorCalendar({homes}:{homes:Array<ContractorHome>}) {
    return <Calendar
        size={"xl"}
        numberOfColumns={1}
        // value={new Date()}
        // onChange={setDate}
        // type='default'
        weekendDays={[]}
        excludeDate={(d) => false}
        renderDay={(d) => {
            let label = "" //TODO show company unavailability details here
            let isToday = dateIsToday(d)
            return <Tooltip disabled={label.length==0} inline label={label}>
                <Indicator inline label={d.getDate()} size={16} color={isToday?"red":"gray"} position="top-start" offset={10}>
                    <VStack classNames={classNames("p-1")} style={{height:"var(--day-size)",width:"var(--day-size)"}}>
                        <VStack center>
                            {homes.flatMap(h=>{
                                return h.projects.filter(p=>isoStringArrayContainsDate(Object.keys(p.scheduled_dates ?? {}),d))
                                    .map(p=>({home_id:h.home_id,project_id:p.project_id,address:buildExtraShortAddress(h.address),title:p.project_title}))
                            }).map(p=>
                                <Link key={p.project_id} to="/contractor/home/$home_id" params={{home_id:p.home_id}} search={{project:p.project_id}}><DoubleLineBadge colorKey={p.project_id} line1={p.address} line2={p.title} /></Link>
                            )}
                        </VStack>
                    </VStack>

                </Indicator>
                {/*<div className={classNames("w-full h-full flex flex-col items-center justify-center")}>*/}
                {/*    {d.getDate()}*/}
                {/*</div>*/}
            </Tooltip>
        }}
    />
}
function getSoonestProjectDate(home:ContractorHome):string {
    return home.projects.map(p=>getSoonestScheduledDate(p)).sort()[0] ??""
}
function getSoonestScheduledDate(project:ContractorProject):string {
    return Object.keys(project.scheduled_dates??{}).sort()[0]??""
}
function AdminHomesTable({homes}:{homes:Array<ContractorHome>}) {
    const sortedHomes = [...homes]
        .sort((h1,h2)=>getSoonestProjectDate(h1).localeCompare(getSoonestProjectDate(h2)))
    return <Table>
        <Table.Thead>
            <Table.Tr>
                <Table.Th>Address</Table.Th>
                <Table.Th>Projects</Table.Th>
                <Table.Th>Project Date</Table.Th>
            </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
            {sortedHomes.map(h=><Table.Tr key={h.home_id}>
                <Table.Td>
                    <Link
                        to="/contractor/home/$home_id"
                        params={{
                            home_id: h.home_id
                        }}
                    >{buildShortAddress(h.address)}</Link>
                </Table.Td>
                <Table.Td>
                    {
                        h.projects
                            .filter(p=>p.project_type!=PROJECT_TYPE_ISM)
                            .map(p=><div className={"m-2 px-2 items-center inline-flex flex-row bg-primary-light-blue rounded-3xl"} key={p.project_id}>
                                <ProjectIcons inverse rec_numbers={p.recommendations.map(r=>r.original_rec_id)} />
                                <TextBase classNames={"mx-2"} inverse>{p.project_title}</TextBase>
                                </div>)
                    }
                </Table.Td>
                <Table.Td>
                    {getSoonestProjectDate(h)}
                </Table.Td>
            </Table.Tr>)}
        </Table.Tbody>
    </Table>
}