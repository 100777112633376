import { HubspotLineItemAggregate} from "@seeair/schemas";
import {Table} from "@mantine/core";
import React from 'react'

export function LineItemTable({line_items}: { line_items: Array<HubspotLineItemAggregate> }) {
    // console.log(`lineitemtable: ${JSON.stringify(line_items)}`)
    const rows: string[][] = line_items.reduce((acc, v) => [...acc, [
        v.properties.name ?? "",
        v.properties.quantity ?? "0",
        v.properties.price ?? "0",
        v.properties.amount ?? "0",
        v.properties.tax ?? '',
        v.properties.hs_discount_percentage ?? '',
        v.properties.discount ?? '',
        v.product?.properties?.recommendation_id ?? ''
    ]], [] as string[][])
    return <Table
        striped
        withTableBorder
        captionSide="bottom"
        data={{
        caption: ` ${rows.length} Line Items from Hubspot`,
        head: ['Name','Quantity','Price per Unit','Total Price','Tax','Discount %','Discount $','Product RecIds'],
        body: rows

    }}/>
}