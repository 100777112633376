import {createFileRoute} from '@tanstack/react-router'
import {trpc} from "@seeair/shared-components";
import { HomePortal} from "@seeair/homeowner-components";
import React from "react";
import {
	HomePageSearch,
	HomePageSearchSchema
} from "@seeair/schemas";

export const Route = createFileRoute('/homes/_homes/$home_id/')({
	component: function HomeDashboardRoute() {
		const {home_id} = Route.useParams()
		const search = Route.useSearch()
		const [home] = trpc.HOMEOWNER.getHomeAggregate.useSuspenseQuery({home_id})
		return <HomePortal home={home} initiallyShowViewer={search?.viewer}/>
	},
	validateSearch: (data)=>{
		const safeParse = HomePageSearchSchema.safeParse(data)
		if(!safeParse.success){
			return {} as HomePageSearch
		}
		return safeParse.data
	}
})
