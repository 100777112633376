import {
    AbsoluteCenterStack,
    DesignedAccordionControl,
    FullWithDesignedSection,
    HStack,
    ProjectIcon,
    Text2Xl,
    TextBase,
    TextError,
    TextSm,
    VStack
} from './DesignBase.js';
import {COLOR_WHITE} from './Theme.js';
import React, {useEffect, useState} from "react";
import {
    buildShortAddress,
    extractBucketAndKeyFromHttpUrl,
    extractBucketAndKeyFromS3Url,
    formatIsoStringArray,
    getLatestFile,
    getLatestFinishedContractorAssessment,
    PROJECT_TYPE_ISM
} from "@seeair/shared";
import {Accordion, Breadcrumbs, CloseButton, Divider, Grid, Image, Loader, Modal, Table, Tabs} from "@mantine/core";
import {Icon3dCubeSphere, IconHammer, IconPhoto} from "@tabler/icons-react";
import {AssessmentFile, ContractorAssessment, ContractorHome, ContractorProject} from "@seeair/schemas";
import classNames from "classnames";
import {Viewer} from './Viewer.js';
import {trpc} from './trpc.js';
import {Badge, DesignedButton, EditableCalendar} from './DesignComponents.js';
import {Link} from "@tanstack/react-router";
import {getUseMutationOpt} from './mutationHelper.js';
import JSZip from "jszip";

export function ContractorPortalHome({home, initiallyShowViewer, selectedProject}: {
    home: ContractorHome,
    initiallyShowViewer?: boolean,
    selectedProject?: string
}) {
    const latestAssessment = getLatestFinishedContractorAssessment(home)

    return <FullWithDesignedSection background={COLOR_WHITE}>
        <Breadcrumbs>
            <Link to="/contractor">Contractor</Link>
            <Link to="/contractor/home/$home_id"
                  params={{home_id: home.home_id}}
                  search={{
                      project: selectedProject,
                      viewer: initiallyShowViewer
                  }}
            >
                Home
            </Link>
        </Breadcrumbs>
        <VStack>

            <HStack leftCenter>
                <Text2Xl>{buildShortAddress(home.address)}</Text2Xl>
                <TextBase classNames={"mx-8"}>{home.owner.name}</TextBase>
                <TextBase light>{home.owner.email}</TextBase>
            </HStack>
            {
                latestAssessment == 'not_found'
                    ? <HStack center><TextBase>No Projects Ready</TextBase></HStack>
                    : <Tabs
                        defaultValue={initiallyShowViewer ? "viewer" : "plan"}
                        classNames={{tab: `border border-gray-500 aria-selected:bg-gray-200`}}
                    >
                        <Tabs.List className="justify-center">
                            <Tabs.Tab value="viewer" leftSection={<Icon3dCubeSphere/>}>Digital Twin</Tabs.Tab>
                            <Tabs.Tab value="plan" leftSection={<IconHammer/>}>Project Plan</Tabs.Tab>
                            <Tabs.Tab value="files" leftSection={<IconPhoto/>}>Photos</Tabs.Tab>
                        </Tabs.List>
                        <div className={"my-12"}>
                            <Tabs.Panel value="viewer">
                                <ContractorPortalViewer
                                    latestAssessment={latestAssessment}
                                    initiallyShowViewer={!!initiallyShowViewer}
                                    home={home}/>
                            </Tabs.Panel>
                            <Tabs.Panel value="plan">
                                <ContractorPortalProjects home={home} selectedProject={selectedProject}/>
                            </Tabs.Panel>
                            <Tabs.Panel value="files">
                                <ContractorPortalPhotos assessment={latestAssessment}/>
                            </Tabs.Panel>
                        </div>
                    </Tabs>
            }
        </VStack>
    </FullWithDesignedSection>
}

function ContractorPortalPhotos({assessment}: { assessment: ContractorAssessment }) {
    const {data: presignedImageUrls} = trpc.CONTRACTOR.getAssessmentImagesDownloadUrls.useQuery({
        assessment_id: assessment.assessment_id,
        home_id: assessment.home_id
    }, {retry: false})
    return <VStack>
        <Grid>
            {
                Object.values(assessment.assessment_files_list ?? {})
                    .filter(f => f.type.endsWith("_image"))
                    .map(fv => {
                        const presignedImageUrl = presignedImageUrls?.urls?.find(o => {
                            const {Bucket: fvBucket, Key: fvKey} = extractBucketAndKeyFromS3Url(fv.s3_url)
                            const {Bucket: presignedBucket, Key: presignedKey} = extractBucketAndKeyFromHttpUrl(o)
                            return fvBucket == presignedBucket && fvKey == presignedKey
                        })
                        return <Grid.Col key={fv.s3_url} span={{base: 12, md: 6, lg: 3}}>
                            {
                                presignedImageUrls
                                    ? <Image
                                        radius="sm"
                                        src={presignedImageUrl}
                                    />
                                    : <Loader/>
                            }

                        </Grid.Col>
                    })
            }
        </Grid>
        <HStack center><TextBase>If you require any other information, please do not hesitate to reach out to <a
            href={"mailto:support@seeair.com"}>SeeAir Support</a></TextBase></HStack>
    </VStack>
}

function SchedulingStatusBadge({project}: { project: ContractorProject }) {
    if (Object.keys(project.scheduled_dates ?? {}).length > 0) {
        return <Badge blue><b>Scheduled</b><br/>{formatIsoStringArray(Object.keys(project.scheduled_dates!))}</Badge>
    } else {
        if ((project.homeowner_availability ?? []).length > 0) {
            return <Badge red>Schedule Now</Badge>
        } else {
            return <Badge gray>Awaiting Homeowner Schedule</Badge>
        }
    }
}

function ContractorPortalProjects({home, selectedProject}: { home: ContractorHome, selectedProject?: string }) {

    return <Accordion
        transitionDuration={300}
        maw={"100%"}
        variant="separated"
        radius="xl"
        defaultValue={selectedProject ?? home.projects[0]?.project_id}
    >
        {
            home.projects.filter(p => p.project_type != PROJECT_TYPE_ISM).map(p => {
                return <Accordion.Item
                    key={p.project_id}
                    value={p.project_id}
                    className="border-primary-light-blue mx-4">
                    <DesignedAccordionControl
                        rec_numbers={p.recommendations.map(r => r.original_rec_id)}
                        title={p.project_title}
                        badgeRight={<SchedulingStatusBadge project={p}/>}
                    />
                    <Accordion.Panel>
                        <ContractorProjectPanel project={p}/>
                    </Accordion.Panel>
                </Accordion.Item>
            })
        }
    </Accordion>
}

function ContractorProjectPanel({project}: { project: ContractorProject }) {
    const {
        isPending: isSetScheduledDatesPending,
        isSuccess: isSetScheduledDatesSuccess,
        mutate: setScheduledDates
    } = trpc.CONTRACTOR.setScheduledDatesForProject.useMutation(getUseMutationOpt(trpc.useUtils()))
    const lineItems = project.recommendations
        .flatMap(r =>
            Object.values(r.line_items ?? {})
                .map(li => ({...li, recNum: r.original_rec_id})))
    return <VStack>
        {
            (project.scheduled_dates ?? []).length == 0 && (project.homeowner_availability ?? []).length > 0 &&
            <HStack center>
                <EditableCalendar
                    alwaysEditing={true}
                    shouldDisableDay={(d) => {
                        if (!project.homeowner_availability?.includes(d)) {
                            return "Homeowner Unavailable"
                        }
                        return ""
                    }}
                    title={"Scheduled Dates"}
                    initialValue={Object.keys(project.scheduled_dates ?? {})}
                    isPending={isSetScheduledDatesPending}
                    isSuccess={isSetScheduledDatesSuccess}
                    save={(dates) => setScheduledDates({
                        home_id: project.home_id,
                        project_id: project.project_id,
                        scheduled_dates: dates
                    })}
                />
            </HStack>
        }
        <Table striped highlightOnHover withTableBorder>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th></Table.Th>
                    <Table.Th>Item</Table.Th>
                    <Table.Th>Quantity</Table.Th>
                    <Table.Th>Description</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {
                    lineItems
                        .map(li =>
                            <Table.Tr key={li.line_item_id}>
                                <Table.Td><ProjectIcon sm rec_number={li.recNum}/></Table.Td>
                                <Table.Td>{li.name}</Table.Td>
                                <Table.Td>{li.quantity}</Table.Td>
                                <Table.Td>{li.description}</Table.Td>
                            </Table.Tr>)
                }
            </Table.Tbody>
            <Table.Tfoot>
                <Table.Tr>
                    <Table.Td colSpan={4}><VStack
                        center><TextSm>{`${lineItems.length} line items defined`}</TextSm></VStack></Table.Td>
                </Table.Tr>
            </Table.Tfoot>
        </Table>
    </VStack>
}

function ContractorPortalViewer3D({latest3dRenderingFile, home,initiallyShowViewer}: {
    latest3dRenderingFile: AssessmentFile,
    home: ContractorHome,
    initiallyShowViewer:boolean

}) {
    const [showViewer, setShowViewer] = useState(initiallyShowViewer)
    const {
        data: urnResponse,
        error: urnError,
        isPending: urnPending
    } = trpc.CONTRACTOR.getConversionInfoForAssessmentFile.useQuery({
        home_id: home.home_id,
        s3_url: latest3dRenderingFile.s3_url
    }, {retry: false})
    if (urnPending) {
        return <VStack relative><AbsoluteCenterStack><Loader/></AbsoluteCenterStack></VStack>
    } else if (urnError) {
        return <VStack relative><AbsoluteCenterStack><TextError>Error Loading Digital
            Twin: {urnError.message}</TextError></AbsoluteCenterStack></VStack>

    } else if (!urnResponse || !urnResponse.urn || !urnResponse.objectExists || urnResponse.conversionStatus != 'success') {
        return <HStack center><TextBase>Model Not Yet Ready</TextBase></HStack>
    } else {
        return <div>
            <Modal classNames={{body: "p-0"}} withCloseButton={false} fullScreen opened={showViewer}
                   onClose={() => setShowViewer(false)}>
                <VStack>
                    <CloseButton size="xl" className="absolute top-2 right-2 z-50"
                                 onClick={() => setShowViewer(false)}/>
                    {urnResponse && <ContractorViewer urn={urnResponse.urn}/>}
                </VStack>
            </Modal>
            <VStack center>
                <DesignedButton onClick={() => setShowViewer(true)}>Launch 3D Viewer</DesignedButton>
            </VStack>
        </div>
    }
}
function ContractorPortalViewer2D({home,assessment}:{assessment:ContractorAssessment,home:ContractorHome}) {
    const [floorPlanImageUrls,setFloorPlanImageUrls] = useState([] as Array<string>)
    const {
        data:floorPlanZipUrl,
        isPending:floorPlanZipIsPending,
        error: floorPlanZipError
    } = trpc.CONTRACTOR.getAssessmentFloorPlanZipDownloadUrl.useQuery({
        home_id: home.home_id,
        assessment_id: assessment.assessment_id
    })
    useEffect(() => {
        void (async () => {
            if(floorPlanZipUrl && floorPlanZipUrl.url) {
                const zipFileResponse = await fetch(floorPlanZipUrl.url)
                const zipFileBlob = await zipFileResponse.blob()
                const zip = new JSZip();
                const content = await zip.loadAsync(zipFileBlob);
                const urls = [];
                for (const fileName in content.files) {
                    if (fileName.endsWith(".png")) {
                        const fileData = await content.files[fileName]!.async("blob");
                        const url = URL.createObjectURL(fileData);
                        urls.push(url);
                    }
                }
                setFloorPlanImageUrls(urls)
            }
        })()
    }, [floorPlanZipUrl]);
    if(floorPlanZipIsPending) {
        return <VStack classNames={"relative"}><AbsoluteCenterStack><Loader /></AbsoluteCenterStack></VStack>
    } else if(floorPlanZipError) {
        return <VStack classNames={"relative"}><AbsoluteCenterStack><TextError>Error Loading Floor Plan: {floorPlanZipError.message}</TextError></AbsoluteCenterStack></VStack>
    }
    return <VStack>
        <Grid>
            {
                floorPlanImageUrls.map((url,i) => <Grid.Col
                    key={i} span={{base: 12, lg: 6}}>
                    <Image
                        radius="sm"
                        src={url}
                    />
                    </Grid.Col>)
            }
        </Grid>
    </VStack>
}
function ContractorPortalViewer({home, initiallyShowViewer, latestAssessment}: {
    home: ContractorHome,
    initiallyShowViewer: boolean,
    latestAssessment: ContractorAssessment,
}) {
    const latest3dRenderingFile = getLatestFile(latestAssessment, 'capture_rendering_enhanced')

    return <div>

        {/*{threeDThumbnailSrc && <img src={threeDThumbnailSrc.url} />}*/}
        <Divider my="sm" label={<TextSm>3D Digital Twin</TextSm>} labelPosition="center" color={"black"}/>
        {
            latest3dRenderingFile == null || latest3dRenderingFile == 'not_found'
                ? <VStack relative><AbsoluteCenterStack><TextBase>Model Not Yet
                    Ready</TextBase></AbsoluteCenterStack></VStack>
                : <ContractorPortalViewer3D home={home} initiallyShowViewer={initiallyShowViewer} latest3dRenderingFile={latest3dRenderingFile}/>
        }
        <Divider my="sm" label={<TextSm>2D Floorplan</TextSm>} labelPosition="center" color={"black"}/>
        {
            <ContractorPortalViewer2D home={home} assessment={latestAssessment}/>
        }

    </div>

}

function ContractorViewer({urn}: { urn: string }) {
    return <Viewer
        urn={urn}
        onRecToggleChange={() => {
        }}
        recs={[]}
        isContractor={true}
        className={classNames("h-screen w-screen")}/>
}