import {Button, FileInput, InputError, InputLabel, Loader, Table,} from '@mantine/core'
import {notifications} from '@mantine/notifications'
import dayjs from 'dayjs'
import React, {ReactNode, useState} from 'react'
import {trpc} from "./trpc.js"
import {
    Assessment,
    EnergyDataRecord,
    HomeAggregate
} from '@seeair/schemas'
import {
    formatNumber,
    hasGasStove,
    hasGasWaterHeater,
    hasPrimaryCooling,
    hasPrimaryGasHeat, hasPrimaryOilHeat, shortDateFormat
}
    from "@seeair/shared"
import {parse as parseCsv} from 'csv-parse/browser/esm/sync'
import {captureException, getCurrentScope} from '@sentry/react'
import {Box, HDivider, HStack, RStack, Text2Xl, TextBase, TextLg, VStack} from "./DesignBase.js";
import {DesignedButton} from "./DesignComponents.js";
import {getUseMutationOpt} from './mutationHelper.js';


async function parseUtilityData(file: File | null): Promise<Array<EnergyDataRecord> | undefined> {
    let _fileText
    if (!file || !(_fileText = await file.text())) return undefined

    let rows
    try {
        rows = parseCsv(_fileText, {relax_column_count: true}) as Array<Array<string>>
    } catch (err) {
        throw new Error(`Failed to parse file: ${file.name}`)
    }

    const greenHeaderIndex = rows.findIndex((row) => row.includes('Usage per day'))
    const natlElectricHeaderIndex = rows.findIndex((row) => row.includes('USAGE (kWh)'))
    const natlGasHeaderIndex = rows.findIndex((row) => row.includes('USAGE (therms)'))

    const toNum = (inp: string | undefined) => Number(inp?.replace(/[,$]/g, '') ?? '')

    if (greenHeaderIndex !== -1) {
        const headers = rows[greenHeaderIndex]!
        const data = rows.slice(greenHeaderIndex + 1)

        return data.map((row) => ({
            date: dayjs(row[headers.indexOf('Read Date')]).toISOString(),
            usage: toNum(row[headers.indexOf('Usage')]),
            cost: toNum(row[headers.indexOf('Charge')]),
            estimated:false
        }))
    } else if (natlElectricHeaderIndex !== -1) {
        const headers = rows[natlElectricHeaderIndex]!
        const data = rows.slice(natlElectricHeaderIndex + 1)

        return data.map((row) => ({
            date: dayjs(row[headers.indexOf('END DATE')]).toISOString(),
            usage: toNum(row[headers.indexOf('USAGE (kWh)')]),
            cost: toNum(row[headers.indexOf('COST')]),
            estimated:false
        }))
    } else if (natlGasHeaderIndex !== -1) {
        const headers = rows[natlGasHeaderIndex]!
        const data = rows.slice(natlGasHeaderIndex + 1)

        return data.map((row) => ({
            date: dayjs(row[headers.indexOf('END DATE')]).toISOString(),
            usage: toNum(row[headers.indexOf('USAGE (therms)')]),
            cost: toNum(row[headers.indexOf('COST')]),
            estimated:false
        }))
    } else {
        throw new Error('No data found. Make sure you are uploading a supported format')
    }
}

function EnergyDataTable({data}:{data:Array<EnergyDataRecord>}) {
    return <Table striped highlightOnHover withTableBorder>
        <Table.Thead>
            <Table.Tr>
                <Table.Th>Date</Table.Th>
                <Table.Th>Days</Table.Th>
                <Table.Th>Usage</Table.Th>
                <Table.Th>Cost</Table.Th>
            </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
            {
                data.map(u => <Table.Tr key={u.date}>
                    <Table.Td>{shortDateFormat(u.date)}</Table.Td>
                    <Table.Td>{u.num_days}</Table.Td>
                    <Table.Td>{formatNumber(u.usage)}</Table.Td>
                    <Table.Td>{formatNumber(u.cost)}</Table.Td>
                </Table.Tr>)
            }
        </Table.Tbody>
    </Table>
}

export function EditUtilitiesPanel({home, assessment}: {
    home: HomeAggregate
    assessment: Assessment
}) {
    const [electric, setElectric] = useState<File | null>(null)
    const [gas, setGas] = useState<File | null>(null)
    const [error, setError] = useState<ReactNode | null>(null)
    const setUtilitiesData = trpc.HOMEOWNER.setUtilitiesData.useMutation(getUseMutationOpt(trpc.useUtils()))
    const gas_usage = Object.values(assessment.gas_usage ?? {})
    const oil_usage = Object.values(assessment.oil_usage ?? {})
    const electric_usage = Object.values(assessment.electric_usage ?? {})
    return (<VStack>
            <RStack>
                <VStack>
                    <Text2Xl>Gas</Text2Xl>
                    <EnergyDataTable data={gas_usage}/>
                </VStack>
                <VStack>
                    <Text2Xl>Oil</Text2Xl>
                    <EnergyDataTable data={oil_usage}/>
                </VStack>
                <VStack>
                    <Text2Xl>Electric</Text2Xl>
                    <EnergyDataTable data={electric_usage}/>
                </VStack>
            </RStack>
            <VStack center>
                <DesignedButton onClick={async () => {
                    try {
                        const {
                            gas_usage: gasUsage,
                            electric_usage: electricUsage,
                            oil_usage: oilUsage,
                        } = generateSampleEnergyData(assessment)
                        setUtilitiesData.mutate({
                            home_id: home.home_id,
                            assessment_id: assessment.assessment_id,
                            gasUsage,
                            electricUsage,
                            oilUsage
                        })
                    } catch (e) {
                        notifications.show({message: `Error: ${e}`})
                    }
                }} disabled={setUtilitiesData.isPending}>Set Estimated Energy Usage</DesignedButton>
            </VStack>
            <Box>
                <VStack>
                    <VStack>
                        <TextLg>Upload Your Gas and Electricity Usage</TextLg>
                        <TextBase>Last 12 Months</TextBase>
                    </VStack>
                    <VStack>
                        <HStack>
                            <InputLabel>Electric</InputLabel>
                            <FileInput
                                value={electric}
                                onChange={setElectric}
                                accept=".csv"
                                placeholder="Select Electric Usage File..."
                                clearable
                            />
                        </HStack>
                        <HStack>
                            <InputLabel>Gas</InputLabel>
                            <FileInput
                                value={gas}
                                onChange={setGas}
                                accept=".csv"
                                placeholder="Select Gas Usage File..."
                                clearable
                            />
                        </HStack>
                        {setUtilitiesData.isPending ? (
                            <HStack>
                                <Loader size="2rem"/>
                            </HStack>
                        ) : (
                            <>
                                {(error || setUtilitiesData.error) && (
                                    <InputError className="whitespace-pre">
                                        {error ??
                                            setUtilitiesData.error?.message ??
                                            'Something went wrong...'}
                                    </InputError>
                                )}
                                <Button
                                    type="submit"
                                    onClick={async () => {
                                        try {
                                            setError(null)
                                            setUtilitiesData.reset()

                                            if (!gas && !electric) {
                                                return
                                            }

                                            const [electricUsageRes, gasUsageRes] = await Promise.allSettled([
                                                parseUtilityData(electric),
                                                parseUtilityData(gas),
                                            ])

                                            const isElectricErr = electricUsageRes.status === 'rejected'
                                            const electricUsage = !isElectricErr
                                                ? electricUsageRes.value
                                                : undefined

                                            const isGasErr = gasUsageRes.status === 'rejected'
                                            const gasUsage = !isGasErr ? gasUsageRes.value : undefined

                                            if (isElectricErr || isGasErr) {
                                                throw new Error(
                                                    [
                                                        `Error while processing files`,
                                                        isGasErr && (gasUsageRes.reason as Error)?.message,
                                                        isElectricErr && (electricUsageRes.reason as Error)?.message,
                                                    ]
                                                        .filter(Boolean)
                                                        .join('\n')
                                                )
                                            }

                                            const minEntries = 3
                                            if (electricUsage && electricUsage.length < minEntries) {
                                                throw new Error(
                                                    `Electric data must have at least ${minEntries} entries`
                                                )
                                            }
                                            if (gasUsage && gasUsage.length < minEntries) {
                                                throw new Error(
                                                    `Gas data must have at least ${minEntries} entries`
                                                )
                                            }

                                            setUtilitiesData.mutate({
                                                home_id: assessment.home_id,
                                                assessment_id: assessment.assessment_id,
                                                electricUsage,
                                                gasUsage,
                                            })
                                        } catch (err) {
                                            const scope = getCurrentScope()
                                            if (gas) {
                                                scope.addAttachment({filename: gas.name, data: await gas.text()})
                                            }
                                            if (electric) {
                                                scope.addAttachment({
                                                    filename: electric.name,
                                                    data: await electric.text(),
                                                })
                                            }
                                            captureException(err)
                                            scope.clearAttachments()

                                            setError((err as Error)?.message ?? 'Something went wrong...')
                                        }
                                    }}
                                >
                                    Upload Files
                                </Button>
                            </>
                        )}
                    </VStack>
                </VStack>
            </Box>
            <Box>
                <TextLg>How to Download Your Data</TextLg>
                <TextBase>
                    Look for the Green Button in your account:
                    <img src="/design-assets/download_green.png" alt=""/>
                </TextBase>
                <HDivider/>
                <HStack>
                    <Button
                        onClick={() => window.open("https://www.eversource.com/content/residential/save-money-energy/energy-savings-tips-tools/green-button", "_blank")}
                    >
                        <img src="/design-assets/download_eversource.png" alt=""/>
                    </Button>
                    <Button
                        onClick={() => window.open("https://myaccount.nationalgrid.com/s/account-overview", "_blank")}>
                        <img src="/design-assets/download_national.png" alt=""/>
                    </Button>
                </HStack>
            </Box>
        </VStack>
    )
}

const gasCost = 2.2 // dollars for a therm
const oilCost = gasCost*1.4 // dollars for a therm
const electricityCost = 0.36 // dollars for a kwh
const monthlyGasHeatingUsageMultiplier: { [m: number]: number } = {
    // Month       | HDD   | Multiplier (Therms per sq ft)
    0: 0.12, //January     | 1100  | 0.061
    1: 0.09, //February    | 1000  | 0.056
    2: 0.08, //March       | 800   | 0.044
    3: 0.022, //April       | 200   | 0.022
    4: 0.011, //May         | 50   | 0.011
    5: 0.000, //June        | 0     | 0.000
    6: 0.000, //July        | 0     | 0.000
    7: 0.000, //August      | 0     | 0.000
    8: 0.006, //September   | 50   | 0.006
    9: 0.01,  //October     | 100   | 0.017
    10: 0.05, //November    | 150   | 0.033
    11: 0.090 //December    | 900   | 0.050
}
const monthlyElectricCoolingUsageMultiplier: { [m: number]: number } = {
    //Month       | CDD   | Multiplier (kWh per sq ft)
    0: 0.0,  //January     | 0     | 0.000
    1: 0.0,  //February    | 0     | 0.000
    2: 0.0,  //March       | 0     | 0.000
    3: 0.0,  //April       | 0     | 0.000
    4: 0.1,  //May         | 25    | 0.100
    5: 0.3,  //June        | 150   | 0.300
    6: 0.7,  //July        | 350   | 0.700
    7: 0.6,  //August      | 300   | 0.600
    8: 0.2,  //September   | 100   | 0.200
    9: 0.0,  //October     | 0     | 0.000
    10: 0.0, //November    | 0     | 0.000
    11: 0.0, //December    | 0     | 0.000
}
const monthlyElectricHeatingUsageMultiplier: { [m: number]: number } = {
    //Month       | HDD   | Multiplier (kWh per sq ft)
    0: 0.538,// January     | 1100  | 0.538
    1: 0.489,// February    | 1000  | 0.489
    2: 0.391,// March       | 800   | 0.391
    3: 0.195,// April       | 200   | 0.195
    4: 0.098,// May         | 50   | 0.098
    5: 0.000,// June        | 0     | 0.000
    6: 0.000,// July        | 0     | 0.000
    7: 0.000,// August      | 0     | 0.000
    8: 0.049,// September   | 50   | 0.049
    9: 0.146,// October     | 100   | 0.146
    10: 0.293,//November    | 150   | 0.293
    11: 0.440,//December    | 900   | 0.440
}
const monthlyElectricMiscBaseload: { [m: number]: number } = {
    //Month
    0: 300,// January
    1: 300,// February
    2: 300,// March
    3: 300,// April
    4: 300,// May
    5: 300,// June
    6: 300,// July
    7: 300,// August
    8: 300,// September
    9: 300,// October
    10: 300,//November
    11: 300,//December
}

function generateSampleEnergyData(assessment: Assessment): {
    gas_usage: Array<EnergyDataRecord>,
    electric_usage: Array<EnergyDataRecord>,
    oil_usage:Array<EnergyDataRecord>
} {
    const missingFields: Array<string> = []
    if (!assessment.home_details?.stove) {
        missingFields.push("stove")
    }
    if (!assessment.home_details?.sqft) {
        missingFields.push("sqft")
    }
    if (!assessment.home_details?.primary_heating) {
        missingFields.push("primary_heating")
    }
    if (!assessment.home_details?.primary_cooling) {
        missingFields.push("primary_heating")
    }
    if (missingFields.length > 0) {
        throw `missing required home_details: ${JSON.stringify(missingFields)}`
    }
    const existingGasUsageMonths = Object.values(assessment.gas_usage ?? {}).map(o => dayjs(o.date).month())
    const existingOilUsageMonths = Object.values(assessment.oil_usage ?? {}).map(o => dayjs(o.date).month())
    const existingElectricUsageMonths = Object.values(assessment.electric_usage ?? {}).map(o => dayjs(o.date).month())
    const sqft = assessment.home_details!.sqft!
    const beds = assessment.home_details!.beds!
    const peopleMultiplier = Math.max(beds-1,1) * 1.1
    const gasStove = hasGasStove(assessment.home_details!)
    const gasWaterHeater = hasGasWaterHeater(assessment.home_details!)
    const gasHeat = hasPrimaryGasHeat(assessment.home_details!)
    const oilHeat = hasPrimaryOilHeat(assessment.home_details!)
    const hasCooling = hasPrimaryCooling(assessment.home_details!)
    const new_gas_usage: Array<EnergyDataRecord> = []
    const new_oil_usage: Array<EnergyDataRecord> = []
    const new_electric_usage: Array<EnergyDataRecord> = []
    for (let i = 0; i < 12; i++) {
        const date = dayjs().subtract(i + 1, 'months').endOf('month').startOf('day')
        const daysInMonth = date.daysInMonth()
        const month = date.month()
        if (gasStove || gasHeat || gasWaterHeater) {
            if (!existingGasUsageMonths.includes(month)) {
                const usage: number = (gasStove ? 5 : 0) * peopleMultiplier + (gasWaterHeater ? 10 : 0) * peopleMultiplier + ((monthlyGasHeatingUsageMultiplier[month] ?? 0) * sqft)
                new_gas_usage.push({
                    num_days: daysInMonth,
                    date: date.toISOString(),
                    usage,
                    cost: usage * gasCost,
                    estimated:true
                })
            }
        }
        if(oilHeat) {
            if (!existingOilUsageMonths.includes(month)) {
                const usage: number = (monthlyGasHeatingUsageMultiplier[month] ?? 0) * sqft
                new_oil_usage.push({
                    num_days: daysInMonth,
                    date: date.toISOString(),
                    usage,
                    cost: usage * oilCost,
                    estimated:true
                })
            }
        }
        if (!existingElectricUsageMonths.includes(month)) {
            const heatingUsage = gasHeat ? 0 : ((monthlyElectricHeatingUsageMultiplier[month] ?? 0) * sqft)
            const coolingUsage = hasCooling ? ((monthlyElectricCoolingUsageMultiplier[month] ?? 0) * sqft) : 0
            const baseloadUsage = (monthlyElectricMiscBaseload[month] ?? 0) * peopleMultiplier
            const usage: number = heatingUsage + coolingUsage + (gasWaterHeater ? 0 : 400) + baseloadUsage
            new_electric_usage.push({
                num_days: daysInMonth,
                date: date.toISOString(),
                usage,
                cost: usage * electricityCost,
                estimated:true
            })
        }
    }
    return {gas_usage: new_gas_usage, electric_usage: new_electric_usage,oil_usage: new_oil_usage}
}
