import {
    Assessment,
    AssessmentFile,
    AssessmentFiles,
    AssessmentFilesEnum,
    AssessmentFilesEnumSchema,
    FileUploadNonce,
    HomeAggregate,
} from "@seeair/schemas";
import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {
    Button,
    Combobox,
    FileInput,
    InputBase,
    Loader,
    Popover,
    Table,
    UnstyledButton,
    useCombobox
} from "@mantine/core";
import {trpc} from "./trpc.js"
import {
    IconCircleCheckFilled,
    IconDownload,
    IconExclamationCircle,
    IconFolderOpen,
    IconTrash
} from "@tabler/icons-react";
import {HDivider, HStack, TextLg, TextSm, TextXs, VStack} from "./DesignBase.js";
import {AdminCombobox, DesignedIconButton, DesignedButton} from "./DesignComponents.js";
import classNames from "classnames";
import {useDisclosure} from "@mantine/hooks";
import {getUseMutationOpt} from './mutationHelper.js';
import {
    extractBucketAndKeyFromHttpUrl,
    extractBucketAndKeyFromS3Url,
    extraShortDateFormat,
    getFileVersionsSortedByLatest,
    lastNChars,
    reverseTruncateString
} from "@seeair/shared";

export function AssessmentFilesTables({assessment, home, selected}: {
    assessment: Assessment,
    home: HomeAggregate,
    selected?: string
}) {
    const {data:presignedImageUrls} = trpc.HOMEOWNER.getAssessmentImagesDownloadUrls.useQuery({
        assessment_id: assessment.assessment_id,
        home_id: home.home_id
    },{retry:false})
    const files: AssessmentFiles = assessment.assessment_files_list ?? {}
    const allPossibleFiles = Object.keys(AssessmentFilesEnumSchema.Values) as Array<AssessmentFilesEnum>
    const imageFiles = allPossibleFiles.filter(o => o.endsWith("image"))
    const cubicasaFiles: Array<AssessmentFilesEnum> = ['capture_video', 'capture_rendered_floor_plan', 'capture_rendering_raw']
    const remainingFiles: Array<AssessmentFilesEnum> = allPossibleFiles.filter(o => !imageFiles.includes(o) && !cubicasaFiles.includes(o))
    return <VStack>
        <TextLg>Images</TextLg>
        <AssessmentFilesTable assessment={assessment} home={home} files={files} fileKeys={imageFiles}
                              selected={selected} presignedImageUrls={presignedImageUrls?.urls ?? []}/>
        <HDivider/>
        <TextLg>CubiCasa</TextLg>
        <AssessmentFilesTable assessment={assessment} home={home} files={files} fileKeys={cubicasaFiles}
                              selected={selected} presignedImageUrls={[]}/>
        <HDivider/>
        <TextLg>Remaining</TextLg>
        <AssessmentFilesTable assessment={assessment} home={home} files={files} fileKeys={remainingFiles}
                              selected={selected} presignedImageUrls={[]}/>
        <HDivider/>
    </VStack>
}

export function AssessmentFilesTable({assessment, home, fileKeys, files, selected, presignedImageUrls}: {
    assessment: Assessment,
    home: HomeAggregate,
    fileKeys: Array<AssessmentFilesEnum>,
    files: AssessmentFiles,
    selected?: string,
    presignedImageUrls: Array<string>
}) {
    const [triggerDownloads, setTriggerDownload] = useState(false)
    //TODO scroll to fileRow that matches `selected`
    return <VStack>
        <DesignedButton onClick={() => {
            setTriggerDownload(true)
        }}>Download All Files</DesignedButton>
        <Table striped highlightOnHover withTableBorder classNames={{tr: "min-h-24"}}>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>File</Table.Th>
                    <Table.Th></Table.Th>
                    <Table.Th>Upload</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {fileKeys.map((k) => <AssessmentFilesRow
                    triggerDownload={triggerDownloads}
                    key={k}
                    fileKey={k}
                    fileVersions={Object.values(files).filter(f => f.type == k)}
                    assessment={assessment}
                    home={home}
                    presignedImageUrls={presignedImageUrls}
                />)}
            </Table.Tbody>
        </Table>
    </VStack>
}

export function AssessmentFilesRowVersionsTable({
                                                    assessment,
                                                    fileKey,
                                                    sortedFileVersions,
                                                    home,
                                                    triggerDownload,
                                                    presignedImageUrls
                                                }: {
    assessment: Assessment
    fileKey: AssessmentFilesEnum
    sortedFileVersions: Array<AssessmentFile>,
    home: HomeAggregate,
    triggerDownload: boolean,
    presignedImageUrls: Array<string>
}) {
    const {
        mutate: deleteFile,
        isPending
    } = trpc.ADMIN.deleteAssessmentFileVersion.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <Table>
        <Table.Thead>
            <Table.Tr>
                <Table.Th>Version</Table.Th>
                <Table.Th>Info</Table.Th>
                <Table.Th>Download</Table.Th>
                <Table.Th className="min-w-48">Update</Table.Th>
                <Table.Th>Delete</Table.Th>
            </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
            {sortedFileVersions.map(fv => {

                const presignedImageUrl = presignedImageUrls.find(o => {
                    const {Bucket: fvBucket, Key: fvKey} = extractBucketAndKeyFromS3Url(fv.s3_url)
                    const {Bucket: presignedBucket, Key: presignedKey} = extractBucketAndKeyFromHttpUrl(o)
                    return fvBucket == presignedBucket && fvKey == presignedKey
                })
                return <Table.Tr key={fv.s3_url}>
                    <Table.Td>
                        <HStack>
                            {presignedImageUrl && <img className="w-16 h-16 mr-4" src={presignedImageUrl}/>}
                            <VStack>
                                <TextSm>{fv.name}</TextSm>
                                <TextXs light classNames="px-2">{extraShortDateFormat(fv.created_date)}</TextXs>
                                <TextXs>{fv.created_by}</TextXs>
                            </VStack>
                            <TextXs>{reverseTruncateString(fv.s3_url, 100)}</TextXs>
                        </HStack>
                    </Table.Td>

                    <Table.Td><AssessmentFilesInfoWidget home_id={home.home_id} fileEnum={fileKey}
                                                         selectedFileVersion={fv}/></Table.Td>
                    <Table.Td><AssessmentFileDownloadWidget triggerDownload={triggerDownload}
                                                            selectedFileVersion={fv}/></Table.Td>
                    <Table.Td><AssessmentFileUpdateWidget assessment={assessment}
                                                          file={fv}/></Table.Td>
                    <Table.Td><DesignedIconButton
                        tooltip="Delete Version"
                        icon={<IconTrash/>}
                        disabled={isPending}
                        onClick={() => deleteFile({
                            assessment_id: assessment.assessment_id,
                            file_enum: fileKey,
                            file_id: fv.file_id
                        })}/>
                    </Table.Td>
                </Table.Tr>
            })}
        </Table.Tbody>
    </Table>
}

export function AssessmentFilesRow({triggerDownload, assessment, fileVersions, fileKey, home, presignedImageUrls}: {
    assessment: Assessment,
    home: HomeAggregate,
    fileVersions: Array<AssessmentFile> | undefined,
    fileKey: AssessmentFilesEnum,
    triggerDownload: boolean,
    presignedImageUrls: Array<string>
}) {
    const sortedFileVersions = getFileVersionsSortedByLatest(fileVersions ?? [])
    return <Table.Tr className="h-24">
        <Table.Td>{fileKey}</Table.Td>
        <Table.Td>
            {
                sortedFileVersions.length > 0 &&
                <AssessmentFilesRowVersionsTable sortedFileVersions={sortedFileVersions} assessment={assessment}
                                                 home={home}
                                                 triggerDownload={triggerDownload} fileKey={fileKey}
                                                 presignedImageUrls={presignedImageUrls}/>
            }
        </Table.Td>
        <Table.Td><AssessmentFileUploadWidget assessment_id={assessment.assessment_id} fileKey={fileKey}/></Table.Td>
    </Table.Tr>
}

const renderableFiles: Array<AssessmentFilesEnum> = ['capture_rendering_enhanced', 'capture_rendering_cleaned']
const cqeFiles: Array<AssessmentFilesEnum> = ['capture_rendered_floor_plan']

function AssessmentFilesInfoWidget({fileEnum, selectedFileVersion, home_id}: {
    home_id: string,
    fileEnum: AssessmentFilesEnum,
    selectedFileVersion?: AssessmentFile
}) {

    return <VStack>
        {selectedFileVersion && renderableFiles.includes(fileEnum) &&
            <HStack center>
                <TextSm classNames="mr-2">Renderable</TextSm>
                <RenderableFileInfoWidget selectedFileVersion={selectedFileVersion} home_id={home_id}/>
            </HStack>
        }
        {selectedFileVersion && cqeFiles.includes(fileEnum) &&
            <HStack center><TextSm classNames="mr-2">CQE</TextSm><CQEInfoWidget
                selectedFileVersion={selectedFileVersion}/></HStack>}
        {selectedFileVersion?.metadata &&
            <HStack center><TextSm classNames="mr-2">Metadata</TextSm><FileMetadataInfoWidget
                metadata={selectedFileVersion.metadata}/></HStack>}
    </VStack>
}

function tableFromJson(parsed: any): ReactElement {
    const data = Object.keys(parsed).reduce((acc, v) => {
        let component: any
        if (typeof parsed[v] == 'object') {
            component = tableFromJson(parsed[v])
        } else {
            component = JSON.stringify(parsed[v])
        }
        return [...acc, [v, component]]
    }, [] as Array<Array<ReactNode>>)
    return <Table className="text-white" data={{body: data}}/>
}

function FileMetadataInfoWidget({metadata}: { metadata: string }) {
    // console.log(`metadata widget: ${metadata}`)
    const [opened, {toggle}] = useDisclosure(false);
    let icon
    let component
    let parsed: any
    try {
        parsed = JSON.parse(metadata)
    } catch {
    }
    if (!parsed) {
        icon = <IconExclamationCircle className="text-red-500"/>
        component = <TextSm inverse>There was an error parsing the metadata</TextSm>
    } else {
        icon = <IconCircleCheckFilled className="text-green-500"/>
        component = tableFromJson(parsed)
    }
    return <Popover position="bottom" shadow="md" opened={opened}>
        <Popover.Target>
            <UnstyledButton onClick={toggle}>
                {icon}
            </UnstyledButton>
        </Popover.Target>
        <Popover.Dropdown className="border-0 bg-black p-4 rounded-2xl">
            <VStack>
                {component}
            </VStack>
        </Popover.Dropdown>
    </Popover>
}

function CQEInfoWidget({selectedFileVersion}: { selectedFileVersion: AssessmentFile }) {
    const [opened, {toggle}] = useDisclosure(false);
    const {data, isPending} = trpc.ADMIN.getCQEInfo.useQuery()
    let icon
    let text
    let model_id: string | undefined
    let cqeUrl: string
    if (selectedFileVersion.metadata) {
        try {
            model_id = JSON.parse(selectedFileVersion.metadata).model_id
        } catch {
        }
        if (!model_id) {
            icon = <IconExclamationCircle className="text-red-500"/>
            text = "There was an error parsing the file metadata"
        } else {
            if (data) {
                const {api_key, config_id} = data
                cqeUrl = `https://cubitool.cubi.casa/?c=${config_id}&m=${model_id}&k=${api_key}&loc=en`
                return <IconFolderOpen className="text-green-500 cursor-pointer"
                                       onClick={() => window.open(cqeUrl, "_blank")}/>
            } else if (isPending) {
                icon = <IconExclamationCircle className="text-yellow-500"/>
                text = "Loading"
            } else {
                text = "Error loading config"
                icon = <IconExclamationCircle className="text-red-500"/>
            }
        }
    } else {
        icon = <IconExclamationCircle className="text-red-500"/>
        text = "There was no file metadata to reference"
    }
    return <Popover position="bottom" shadow="md" opened={opened}>
        <Popover.Target>
            <UnstyledButton onClick={toggle}>
                {icon}
            </UnstyledButton>
        </Popover.Target>
        <Popover.Dropdown className="border-0 bg-black p-4 rounded-2xl">
            <VStack>
                <TextSm inverse>{text}</TextSm>)
            </VStack>
        </Popover.Dropdown>
    </Popover>
}

function RenderableFileInfoWidget({home_id, selectedFileVersion}: {
    home_id: string,
    selectedFileVersion: AssessmentFile
}) {
    const [opened, {toggle}] = useDisclosure(false);
    const {data, error, isPending} = trpc.HOMEOWNER.getConversionInfoForAssessmentFile.useQuery({
        home_id: home_id,
        s3_url: selectedFileVersion.s3_url
    },{retry:false})
    if (isPending) {
        return <Loader/>
    }
    let icon
    let text
    if (error) {
        icon = <IconExclamationCircle className="text-red-500"/>
        text = "There was an error loading details about the APS file upload and conversion"
    } else if (data.conversionStatus == 'success') {
        return <IconCircleCheckFilled className="text-green-500"/>
    } else {
        if (data.conversionStatus == 'failed') {
            text = "The APS Conversion Failed"
            icon = <IconExclamationCircle className="text-red-500"/>
        } else {
            icon = <IconExclamationCircle className="text-yellow-500"/>
            if (data.conversionStatus == 'not_found') {
                if (data.objectExists) {
                    text = "The file was uploaded to APS, but the conversion is missing"
                } else {
                    text = "The file has not yet been uploaded to APS"
                }
            }
            text = `Status: ${data.conversionStatus}; Progress: ${data.conversionProgress}`
        }
    }
    return <Popover position="bottom" shadow="md" opened={opened}>
        <Popover.Target>
            <UnstyledButton onClick={toggle}>
                {icon}
            </UnstyledButton>
        </Popover.Target>
        <Popover.Dropdown className="border-0 bg-black p-4 rounded-2xl">
            <VStack>
                <TextSm inverse>{text}</TextSm>)
            </VStack>
        </Popover.Dropdown>
    </Popover>
}

function AssessmentFileVersionSelectWidget({sortedFileVersions, selectedFileVersion, setSelectedFileVersion}: {
    sortedFileVersions: Array<AssessmentFile>,
    selectedFileVersion?: AssessmentFile,
    setSelectedFileVersion: (f: AssessmentFile) => void
}) {

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownOpen: (eventSource) => {
            if (eventSource === 'keyboard') {
                combobox.selectActiveOption();
            } else {
                combobox.updateSelectedOptionIndex('active');
            }
        },
    });
    return <Combobox
        store={combobox}
        size="xl"
        disabled={!sortedFileVersions}
    >
        <Combobox.Target targetType="button">
            <InputBase
                component="button"
                type="button"
                pointer
                rightSection={<Combobox.Chevron/>}
                rightSectionPointerEvents="none"
                onClick={() => combobox.toggleDropdown()}
            >
                {selectedFileVersion
                    ? selectedFileVersion.name
                    : null
                }
            </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown>
            <Combobox.Options>
                {sortedFileVersions.map((f, i) => <UnstyledButton
                    className={classNames("first:border-0 first:mt-0 mt-2 border-t border-black border-solid w-full", {"bg-gray-200": f.s3_url == selectedFileVersion?.s3_url})}
                    key={i}
                    onClick={() => {
                        setSelectedFileVersion(f)
                        combobox.toggleDropdown()
                    }}><FileComboboxItem file={f}/>
                </UnstyledButton>)}
            </Combobox.Options>
        </Combobox.Dropdown>
    </Combobox>
}

function AssessmentFileUploadWidget({assessment_id, fileKey}: {
    assessment_id: string,
    fileKey: AssessmentFilesEnum
}) {
    const [fileUpload, setFileUpload] = useState<File | null>(null)
    const {
        data: saveUrlResponse,
        isError: isSaveUrlError,
        error: saveUrlError,
        isPending: isSaveUrlPending,
        mutate: saveUrl
    } =
        trpc.ADMIN.saveAssessmentFileUploadUrl.useMutation(getUseMutationOpt(trpc.useUtils(), () => setFileUpload(null)))
    const {
        data: fileUploadUrl
        , isError: isUploadFileError,
        error: fileUploadError,
        isPending: isUploadFilePending,
        mutate: uploadFile
    } = trpc.ADMIN.getAssessmentFileUploadUrl.useMutation({
        onSuccess: async ({url, nonce}: { url: string, nonce: FileUploadNonce }) => {
            try {
                await fetch(url, {
                    method: "PUT",
                    body: fileUpload!,
                    headers: {
                        "Content-Type": fileUpload!.type
                    }
                })
                saveUrl(nonce)
            } catch (e) {
                console.log("upload error", e)
            }
        }
    })
    return <HStack leftCenter>
        <FileInput
            value={fileUpload}
            onChange={setFileUpload}
            placeholder={`Select File ...`}
            classNames={{input: "border-black text-black"}}
            clearable
        />
        <Button disabled={!fileUpload || isUploadFilePending}
                onClick={() => uploadFile({
                    assessment_id: assessment_id,
                    contentType: fileUpload!.type,
                    assessmentFileEnum: fileKey,
                    fileName: fileUpload!.name
                })}
        >{isUploadFilePending || isSaveUrlPending ? <Loader/> : "Upload"}</Button>
        {isUploadFileError && <span className="text-error">{fileUploadError.message}</span>}
        {isSaveUrlError && <span className="text-error">{saveUrlError.message}</span>}
    </HStack>
}

function AssessmentFileUpdateWidget({assessment, file}: { assessment: Assessment, file: AssessmentFile }) {
    const {mutate, isPending} = trpc.ADMIN.updateAssessmentFileVersion.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <AdminCombobox
        disabled={isPending}
        options={AssessmentFilesEnumSchema.options.map(o => ({name: o, id: o}))}
        initiallySelectedId={file.type}
        onChange={(value) => mutate({
            assessment_id: assessment.assessment_id,
            file: {...file, type: value.id as AssessmentFilesEnum}
        })}
    />
}

function AssessmentFileDownloadWidget({triggerDownload, selectedFileVersion}: {
    triggerDownload: boolean,
    selectedFileVersion?: AssessmentFile
}) {
    const {
        data: fileDownloadUrl,
        isPending: isFileDownloadUrlPending,
        mutate: getDownloadUrl,
        reset: resetDownloadUrl
    } = trpc.ADMIN.getAssessmentFileDownloadUrl.useMutation({
        onSuccess: async ({url}: { url: string }) => {
            if (triggerDownload) {
                window.open(url, "_blank")
            }
        }
    })
    useEffect(() => {
        if (triggerDownload && selectedFileVersion) {
            getDownloadUrl({s3Url: selectedFileVersion.s3_url})
        }
    }, [triggerDownload, getDownloadUrl, selectedFileVersion]);
    useEffect(() => {
        resetDownloadUrl()
    }, [selectedFileVersion, resetDownloadUrl]);
    return <Button className="h-full" disabled={!selectedFileVersion} onClick={() => {
        if (fileDownloadUrl) {
            window.open(fileDownloadUrl.url, "_blank")
        } else if (selectedFileVersion) {
            getDownloadUrl({s3Url: selectedFileVersion.s3_url})
        }
    }}>
        {fileDownloadUrl ? <IconDownload/> : isFileDownloadUrlPending ?
            <Loader/> :
            <span className="block whitespace-break-spaces max-w-20 break-words">Download</span>}
    </Button>
}

function FileComboboxItem({file}: { file: AssessmentFile }) {
    return <VStack center>
        <TextSm>{lastNChars(file.name, 30)}</TextSm>
        <TextSm>{file.created_by}</TextSm>
        <TextSm>{file.created_date}</TextSm>
    </VStack>
}