import {Text2Xl, TextError, VStack} from "@seeair/shared-components";
import React, {useMemo} from 'react'
import {UnstyledButton} from "@mantine/core";
import { UAParser } from 'ua-parser-js';


export function AppRedirect({url}: { url: string }) {
    const uaResult = useMemo(()=>{
        return new UAParser().getResult();
    },[])
    // const cookies  = parse(document.cookie)
    // const token = cookies["__Secure-authjs.session-token"]||cookies["authjs.session-token"]||"none"
    return <VStack center classNames="flex-grow bg-white h-full min-h-96">
        <UnstyledButton
            className="bg-black rounded text-white text-base font-semibold no-underline px-5 flex flex-row items-center"
            onClick={() => {
                if (url) {
                    window.open(url)
                } else {
                    alert("Something went wrong, please try to log in again or contact support for assistance")
                }
            }}>
            <img className="inline-block mx-2 my-4 w-6 h-6" src={"/design-assets/apple.png"}/><Text2Xl m0>Launch SeeAir App</Text2Xl>
        </UnstyledButton>
        <div className="mt-24">
            {uaResult.browser.name == 'Mobile Firefox' && <TextError>Mobile Firefox cannot launch apps - please try Chrome or Safari</TextError>}
            {uaResult.device.type != 'mobile' && uaResult.device.type != 'tablet' && <TextError>Please open this page on your mobile device to launch the App</TextError>}
        </div>
    </VStack>

}
