import {TextInput} from "@mantine/core";
import React, {useMemo, useState} from "react";
import {HStack, TextSm} from './DesignBase.js';
import Mexp from "math-expression-evaluator";
import {formatMoney} from "@seeair/shared";

export function NumberExpressionInput({money,label, value, onChange,disabled}: {
    label?: string,
    value: number,
    onChange: (n: number) => void,
    money?:boolean
    disabled: boolean
}) {
    const [inputValue, setInputValue] = useState(`${value}`)
    const [expressionValue,error] = useMemo(()=>{
        try{
            return [new Mexp().eval(inputValue),null]
        } catch(e) {
            return [NaN,(e as any).message]
        }
    },[inputValue])
    return <TextInput
        label={label}
        value={inputValue}
        onKeyDown={(e) => {
            if (e.key == 'Tab') {
                if (isFinite(expressionValue) && !isNaN(expressionValue)) {
                    setInputValue(`${expressionValue}`)
                }
            }
        }}
        onChange={(e) => {
            setInputValue(e.currentTarget.value)
            try{
                onChange(new Mexp().eval(e.currentTarget.value))
            } catch (e) {
                // do not update until the expression is fixed
            }
        }}
        disabled={disabled}
        rightSectionWidth={"4rem"}
        rightSection={<HStack center classNames="bg-gray-400"><TextSm light inverse>{money?formatMoney(expressionValue):expressionValue}</TextSm></HStack>}
        error={error}
    />
}