import {
    HubspotLineItem, ProjectAggregate,
    RecommendationDiscounts,
    RecommendationLineItem,
    RecommendationLineItems
} from "@seeair/schemas";
import {Decimal} from 'decimal.js';
type RecommendationLineItemWithDiscount = (RecommendationLineItem & {discount_type:'percent',discount_percentage:Decimal}) | (RecommendationLineItem & {discount_type:'concrete',discount_amount:Decimal})

const Money = Decimal.clone()
export function applyDiscountsToLineItems(line_items:RecommendationLineItems,discounts:RecommendationDiscounts):Array<HubspotLineItem['properties']> {
    const lis:Array<RecommendationLineItemWithDiscount> = Object.values(line_items).map(li=>({
        ...li,
        discount_type: 'percent',
        discount_percentage:new Money(0),
    }))
    for(const d of Object.values(discounts)) {
        console.log(`calculating: ${d.incentive_id}`)
        if(d.timeOfPurchase){
            let remainingCap = new Money(d.cap)
            for(let li of lis) {
                if(remainingCap.greaterThan(0)) {
                    const quantity = new Money(li.quantity)
                    const price_per_unit = new Money(li.price_per_unit)
                    const discountPercentage = new Money(d.percentage)
                    const amount = quantity.times(price_per_unit)
                    const potentialPercentDiscount = amount.times(discountPercentage)
                    if(remainingCap.minus(potentialPercentDiscount).lessThan(0)) {
                        //then we can't give the full percentage discount, and we need to switch it to a fixed discount
                        if(li.discount_type == 'percent') {
                            const previousPercentage = li.discount_percentage
                            //requires some ugly typing
                            li.discount_percentage = new Money(0)
                            ;(li as any).discount_type='concrete'
                            li = li as RecommendationLineItemWithDiscount
                            if(li.discount_type == 'concrete') {
                                li.discount_amount = previousPercentage.times(amount)
                            } else {
                                throw `shouldn't get here`
                            }
                            console.log(`converted LI because of exausted cap: ${JSON.stringify(li)}`)
                        }
                        //now that is fixed we can add the current discount to it
                        //need this for typescript as well
                        if(li.discount_type == 'concrete') {
                            if(li.discount_amount.plus(remainingCap).lessThanOrEqualTo(amount)) {
                                li.discount_amount = li.discount_amount.plus(remainingCap)
                                remainingCap = new Money(0)
                            } else {
                                li.discount_amount = amount
                                remainingCap = remainingCap.minus(amount).minus(li.discount_amount)
                            }
                        } else {
                            throw `shouldn't get here`
                        }

                    } else {
                        //make sure its not already using concrete amount
                        //make sure this won't put us over 100% discount
                        if(li.discount_type == 'percent'){
                            if(li.discount_percentage.plus(d.percentage).lessThanOrEqualTo(1)) { //hubspot won't allow over 100%
                                remainingCap = remainingCap.minus(potentialPercentDiscount)
                                li.discount_percentage = li.discount_percentage.plus(d.percentage)
                            } else {
                                li.discount_percentage = new Money(1)
                                remainingCap = remainingCap.minus( amount.times(new Money(1).minus(li.discount_percentage)))
                            }
                        } else {
                            // make sure the line item won't go negative
                            if(li.discount_amount.plus(potentialPercentDiscount).lessThanOrEqualTo(amount)) {
                                li.discount_amount = li.discount_amount.plus(potentialPercentDiscount)
                                remainingCap = remainingCap.minus(potentialPercentDiscount)
                            } else {
                                li.discount_amount = amount
                                remainingCap = remainingCap.minus(amount.minus(li.discount_amount))
                            }
                        }
                    }
                    console.log(`li updated; ${JSON.stringify(li)}`)
                } else {
                    console.log("remaining cap 0")
                }

            }
        }
    }
    const hlip:Array<HubspotLineItem['properties']> = lis
        .map(li=>({
            name:li.name,
            hs_product_id:li.product_id,
            quantity:`${li.quantity}`,
            price:`${li.price_per_unit}`,
            discount: li.discount_type == 'concrete' ? `${roundPenny(li.discount_amount)}` : null,
            hs_discount_percentage: li.discount_type == 'percent' ? `${li.discount_percentage.times(100).floor()}` : null,
            tax: null,
            amount:`${roundPenny(calculateAmount(li))}`
        }))
    return hlip
}
function calculateAmount(li:RecommendationLineItemWithDiscount):Decimal {
    const undiscountedAmount = new Money(li.quantity).times(li.price_per_unit)
    if(li.discount_type == 'percent') {
        return undiscountedAmount.times(new Money(1).minus(li.discount_percentage))
    } else {
        return undiscountedAmount.minus(li.discount_amount)
    }
}
function roundPenny(d:Decimal):Decimal {
    return d.times(100).floor().div(100)
}
export function calculateDealAmount(project:ProjectAggregate):string{
    const dealAmount = project.recommendations
        .flatMap(r=>Object.values(r.line_items ?? {}))
        .reduce((acc,v)=>acc.plus(new Money(v.quantity).times(v.price_per_unit)),new Money(0))
    return roundPenny(dealAmount).toString()
}