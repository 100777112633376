import {
    ProjectAggregate,
} from "@seeair/schemas";
import {
    HStack,
    ProjectIcon,
    TextBase,
    TextLg,
    TextLink,
    VStack
} from './DesignBase.js';
import React, { useState} from "react";
import {Accordion, Tabs} from "@mantine/core";
import {
    getProjectStage,
    hubspotLineItemsToProformaData,
    recommendationLineItemsToProformaData
} from "@seeair/shared";
import classNames from "classnames";
import {RecommendationSummaryPanel} from './RecommendationsAccordionCommon.js';
import {Proforma} from './Proforma.js';
import {IconCalendar, IconCurrencyDollar} from "@tabler/icons-react";
import {trpc} from './trpc.js';
import {getUseMutationOpt} from './mutationHelper.js';
import dayjs from "dayjs";
import {EditableCalendar} from './DesignComponents.js';


export function ProjectPanel({project}: { project: ProjectAggregate }) {
    const [recSummarySelected,setRecSummarySelected] = useState(null as string | null)
    const [tabSelected,setTabSelected] = useState("cost" as string | null)
    return <VStack>
        <VStack classNames="px-4">
            <div className="pt-4">
                <Accordion
                    transitionDuration={300}
                    value={recSummarySelected}
                    onChange={setRecSummarySelected}>
                    {project.recommendations.map(r => <Accordion.Item
                        value={r.original_rec_id}
                        key={r.recommendation_id}
                        classNames={{item:classNames({"background-light-grey":recSummarySelected==r.original_rec_id})}}
                    >
                        <Accordion.Control>
                            <HStack leftCenter>
                                <ProjectIcon rec_number={r.original_rec_id} />
                                <TextLg m0 classNames="inline-block w-64 px-4 min-w-32">{r.title}</TextLg>
                                <TextBase>{r.description}</TextBase>
                            </HStack>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <RecommendationSummaryPanel
                                rec={r}
                                key={r.recommendation_id}
                            />
                        </Accordion.Panel>
                    </Accordion.Item>)}
                </Accordion>
            </div>
        </VStack>
        <Tabs
            value={tabSelected}
            onChange={setTabSelected}
            classNames={{tab:`border border-gray-500 aria-selected:bg-gray-200`}}
        >
            <Tabs.List className="justify-center">
                <Tabs.Tab value="cost" leftSection={<IconCurrencyDollar />}>Cost</Tabs.Tab>
                <Tabs.Tab value="scheduling" leftSection={<IconCalendar />}>Scheduling</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="cost">
                <ProjectPanelCost project={project} />
            </Tabs.Panel>
            <Tabs.Panel value="scheduling">
                <ProjectPanelScheduling project={project} />
            </Tabs.Panel>
        </Tabs>

    </VStack>
}
function ProjectPanelScheduling({project}:{project:ProjectAggregate}) {

    const {
        isPending: isSetHomeownerAvailabilityPending,
        isSuccess: isSetHomeownerAvailabilitySuccess,
        mutate: setHomeownerAvailability
    } = trpc.HOMEOWNER.setHomeownerAvailabilityForProject.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack center>
        <TextBase>Please fill in your availability for execution of the project. Selecting a date means that an adult will be present from ~7:30am-4:30pm.
            Please select as many groups of contiguous dates as possible in the next 6 weeks.</TextBase>
        <EditableCalendar
            startingDayIsoString={dayjs().add(2,'weeks').toISOString()}
            alwaysEditing={true}
            shouldDisableDay={(d)=>{
                const day = dayjs(d)
                const dayOfWeek = day.get("day")
                const today = dayjs()
                if(day.isBefore(today.add(2,'weeks'))){
                    return "Please select a date at least two weeks out from today"
                }
                if(day.isAfter(today.add(6,'weeks'))) {
                    return "Please select a date less then six weeks out from today"
                }
                if(dayOfWeek == 0 || dayOfWeek == 6) {
                    return "Weekend"
                }
                return ""
            }}
            title={"Your Availability"}
            initialValue={project.homeowner_availability ?? []}
            isPending={isSetHomeownerAvailabilityPending}
            isSuccess={isSetHomeownerAvailabilitySuccess}
            save={(dates)=>setHomeownerAvailability({home_id:project.home_id,project_id:project.project_id,homeowner_availability:dates})}
        />
    </VStack>
}
function ProjectPanelCost({project}:{project:ProjectAggregate}) {
    const stage = getProjectStage(project)
    return project.recommendations.some(o=>Object.values(o.line_items??{}).length > 0)
            ? <VStack center>
                <Proforma title="Quoted Price"
                          data={recommendationLineItemsToProformaData(project.recommendations)}/>
                {project.hubspot_quote_url &&
                    <a href={project.hubspot_quote_url} target="_blank" rel="noreferrer">
                        <TextLink>View Quote, Sign & Pay</TextLink>
                    </a>}
            </VStack>
            : stage == 'drafting'
                ? <TextBase classNames="px-16">We are still drafting our proposal of work</TextBase>
                : <VStack center>
                    <Proforma title="Quoted Price"
                              data={hubspotLineItemsToProformaData(
                                  project.hubspot_quote_line_items ?? [],
                                  project.recommendations)}/>
                    {project.hubspot_quote_url &&
                        <a href={project.hubspot_quote_url} target="_blank" rel="noreferrer">
                            <TextLink>View Quote, Sign & Pay</TextLink>
                        </a>}
                </VStack>
}